import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

import { initializeAdTracking } from '@/analytics'

import Checkmark from '@/assets/iconography/checkmark.svg'

import Spinner from '@/components/Spinner'
import FormReview from '@/components/FormReview'

import './index.css';

export default class ReviewClass extends Component {
  state = {
    loading: true,
    rating: null,
    review: '',
    sendingReview: false,
    success: false
  }
  componentDidMount = async () => {
    initializeAdTracking(this.props.location.pathname);
    let activity, partner, booking
    if(this.props.history.location.search){
      let params = await new URLSearchParams(this.props.history.location.search);
      let token = params.get('token')
      this.setState({
        token
      })
      const BOOKING_RESPONSE = await fetch(`/api/bookings/review/${token}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': 'Origin'
        }
      });
      const BOOKING_DATA = await BOOKING_RESPONSE.json();
      if(BOOKING_DATA.auth) {
        activity = BOOKING_DATA.activity
        partner = BOOKING_DATA.partner
        booking = BOOKING_DATA.booking
      }
    }
    await this.setState({
      loading: false,
      activity,
      partner,
      booking
    })
    console.log(this.state)
  }
  setRating = (rating) => {
    this.setState({
      rating: rating,
      error: rating < 4 && this.state.review.length <= 0 ? 'Poor ratings must have a review. Let other parents know why you rated this activity the way you did!' : null
    });
  }
  setReview = (review) => {
    this.setState({
      review: review,
      error: this.state.rating < 4 && this.state.rating && review.length <= 0 ? 'Poor ratings must have a review. Let other parents know why you rated this activity the way you did!' : null
    });
  }
  sendReview = async () => {
    this.setState({
      sendingReview: true
    })
    if(!this.state.rating && (this.state.rating < 4 && this.state.review.length <= 0)) {
      this.setState({
        sendingReview: false,
        error: 'Please enter all required information'
      })
    } else {
      const REVIEW_RESPONSE = await fetch(`/api/bookings/review/${this.state.token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': 'Origin'
        },
        body: JSON.stringify({
          rating: this.state.rating,
          review: this.state.review
        })
      });
      const REVIEW_DATA = await REVIEW_RESPONSE.json();
      if(REVIEW_DATA.auth) {
        this.setState({
          success: true,
          sendingReview: false
        })
      } else {
        this.setState({
          sendingReview: false,
          success: false,
          error: REVIEW_DATA.error
        })
      }
    }
  }
  render() {
    if(this.state.loading || this.state.sendingReview) {
      return <Spinner />
    }
    if(this.state.success) {
      return (
        <div className = 'bookingConfirmationContainer'>
          <div className = 'paymentConfirmationCheckmarkContainer'>
            <img
              className = 'paymentConfirmationCheckmark'
              src = {Checkmark}
              alt = 'Find new children activities'
            />
          </div>
          <div className = 'bookingConfirmationHeaderContainer'>
            <h1>Thanks for reviewing <strong>{this.state.activity.name}</strong>!</h1>
            <span className = 'reviewConfirmationText'>Your review means a lot and helps other parents just like yourself. Thanks again and we hope you enjoyed your time at {this.state.partner.name}!</span>
          </div>
          <div className = 'bookingConfirmationShareContainer'>
            <span className = 'reviewConfirmationSubheaderText'>Enjoy the class? Book it again by clicking below!</span>
            <NavLink to = {`/${this.state.partner.slug}/${this.state.activity.slug}`}>
              <button>Book it again!</button>
            </NavLink>
          </div>
        </div>
      );
    }
    return (
      <div className = 'reviewContainer'>
        <h1 className = 'reviewHeaderText'>How was {this.state.activity.name}?</h1>
        <span className = 'reviewSubtitleText'>Let other parents know how you and your little one enjoyed {this.state.partner.name}!</span>
        <FormReview
          sendReview = {this.sendReview}
          setRating = {this.setRating}
          setReview = {this.setReview}
          activity = {this.state.activity}
          partner = {this.state.partner}
          rating = {this.state.rating}
          review = {this.state.review}
          error = {this.state.error}
        />
        <span className = 'reviewSmallText'>Reviews help other parents just like you pick the best activities for their little ones. Be as honest and candid as possible!</span>
      </div>
    );
  }
}