import React, { Component } from 'react';

import CardActivity from '@/components/CardActivity';
import CardSemester from '@/components/CardSemester';
import CardSession from '@/components/CardSession';
import CardProvider from '@/components/CardProvider';
import CardChild from '@/components/CardChild';

import LineSemester from '@/components/LineSemester';
import LineSession from '@/components/LineSession';

import './index.css';

export default class Mapper extends Component {
  render() {
    if(this.props.type === 'lineSession' && this.props.content) {
      return (
        <div>
          {
            this.props.content.map(item => {
              return (
                <LineSession content = {item} key = {item} />
              );
            })
          }
        </div>
      );
    }
    else if(this.props.type === 'lineSemester' && this.props.content) {
      return (
        <div>
          {
            this.props.content.map(item => {
              return(
                <LineSemester content = {item} key = {item} />
              );
            })
          }
        </div>
      );
    } else if(this.props.type === 'semester' && this.props.content) {
      return (
        <div>
          {
            this.props.content.map(item => {
              return(
                <CardSemester semester = {item} key = {item} />
              );
            })
          }
        </div>
      );
    } else if(this.props.type === 'session' && this.props.content) {
      return (
        <div>
          { 
            this.props.content.map(item => {
              return(
                <CardSession content = {item} key = {item} />
              );
            })
          }
        </div>
      );
    } else if(this.props.type === 'activity' && this.props.content) {
      return (
        <div className = 'mapperContainer'>
          { 
            this.props.content.map(item => {
              return(
                <CardActivity activity = {item} key = {item} />
              );
            })
          }
        </div>
      );
    } else if(this.props.type === 'provider' && this.props.content) {
      return (
        <div>
          { 
            this.props.content.map(item => {
              return(
                <CardProvider partner = {item} key = {item} />
              );
            })
          }
        </div>
      );
    } else if(this.props.type === 'children' && this.props.content) {
      return (
        <div>
          {
            this.props.content.map(item => {
              return(
                <CardChild child = {item} key = {item}/>
              );
            })
          }
        </div>
      );
    }
    return (
      <div>
        <p>No results found</p>
      </div>
    );
  }
}