import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import NotFoundImage from '@/assets/images/not-found.png';

import './index.css';

export default class NotFound extends Component {
  render() {
    return (
      <div className = 'notFoundContainer'>
        <h1 className = 'defaultErrorText'>There's nothing here.</h1>
        <img
          src = {NotFoundImage}
          alt = 'PlayPass not found'
          className = 'notFoundImage'
        />
        <p>What you were looking for doesn't exist.</p>
        <NavLink to ='/'>
          <button>
            Back to Main Page
          </button>
        </NavLink>
      </div>
    );
  }
}