import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import moment from 'moment'

import { STORAGE_KEY } from '@/constants'

import Spinner from '@/components/Spinner'

export default class SelectedSession extends Component {
  state = {
    loading: true,
    bookings: [],
  }
  componentDidMount = async () => {
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY);
    if(this.props.session.endDate < moment().unix()) {
      this.setState({
        ended: true,
      })
    } else if(!this.props.session.costInCents) {
      this.setState({
        price: (this.props.activity.costInCents / 100).toFixed(2),
        startTime: moment.unix(this.props.session.startDate).format('ddd MMM Do YYYY, h:mm a'),
        endTime: moment.unix(this.props.session.endDate).format('h:mm a'),
        semester: false
      })
    } else if(this.props.session.prorate && this.props.session.startDate < moment().unix()) {
      const SEMESTER_RESPONSE = await fetch(`/api/sessions/semester/${this.props.session._id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': 'Origin'
        }
      });
      const SEMESTER_DATA = await SEMESTER_RESPONSE.json();
      if(SEMESTER_DATA.auth){
        let sessions = SEMESTER_DATA.sessions
        const sessionsRemainingInTerm = await sessions.filter(session => session.session.startDate > moment().unix());
        this.setState({
          price: isNaN((this.props.session.costInCents * (sessionsRemainingInTerm.length/sessions.length)/100).toFixed(2)) ? 0 : (this.props.session.costInCents * (sessionsRemainingInTerm.length/sessions.length)/100).toFixed(2),
          startTime: moment.unix(this.props.session.startDate).format('MMM Do YYYY'),
          endTime: moment.unix(this.props.session.endDate).format('MMM Do YYYY'),
          semester: true
        });
      }
    } else {
      this.setState({
        price: (this.props.session.costInCents/100).toFixed(2),
        startTime: moment.unix(this.props.session.startDate).format('MMM Do YYYY'),
        endTime: moment.unix(this.props.session.endDate).format('MMM Do YYYY'),
        semester: true
      });
    }
    if(this.props.session.startDate < moment().unix()) {
      this.setState({
        started: true
      })
    }
    if(DEMO_TOKEN) {
      const BOOKING_RESPONSE = await fetch(`/api/bookings/${this.props.session._id}`, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + DEMO_TOKEN,
          'x-access-token': DEMO_TOKEN
        }
      });
      const BOOKING_DATA = await BOOKING_RESPONSE.json();
      if(BOOKING_DATA.auth) {
        let bookings = BOOKING_DATA.bookings
        this.setState({
          bookings: bookings.filter(booking => booking.status === 'ATTEND' && booking.type !== 'locked')
        });
      }
    }
    this.setState({
      loading: false
    })
  }
  render() {
    if(this.state.loading) {
      return <Spinner />
    }
    if(this.state.ended) {
      return (
        <div className = 'activitySection'>
          <h1 className = 'defaultSubheaderText'>Your class ended!</h1>
          <div className = 'activityDescriptiveContainer'>
            <div className = 'sessionSelectedContainer'>
              <span className = 'defaultErrorText'>Class Ended!</span>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className = 'activitySection'>
        <h1 className = 'defaultSubheaderText'>{this.state.semester ? `Register for this term` : `Book your class for $${this.state.price}`} </h1>
        <div className = 'activityDescriptiveContainer'>
          <div className = 'sessionSelectedContainer'>
            <div className = 'lineSemesterContainer'>
              {
                this.state.semester &&
                <span className = 'lineSemesterBoldText'>{this.props.session.frequency} for ${this.state.price}</span>
              }
              <span className = 'dropInDateText'>{this.state.startTime} – {this.state.endTime}</span>
            </div>
            {
              this.state.bookings.length > 0 &&
              <NavLink className = 'defaultSmallText dropInCancelLink' to = {`/cancel/${this.props.session._id}`}>
                Cancel
              </NavLink>
            }
            {
              this.props.session.classFull ?
              (
                <span className = 'defaultSmallText dropInCancelLink'>No more spots!</span>
              ) :
              (
                <NavLink to = {`/book/${this.props.session._id}`}>
                  <button>
                    {this.state.semester ? 'Register' : 'Book Class'}
                  </button>
                </NavLink>
              )
            }
          </div>
          <div className = 'activityDescriptiveContainer'>
            {
              this.state.started &&
              <span className = 'defaultErrorText'>This class already started! Make sure this is the correct class you want to book.</span>
            }
          </div>
        </div>
      </div>
    );
  }
}