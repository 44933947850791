import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import moment from 'moment';

import { STORAGE_KEY } from '@/constants';
import { initializeAdTracking } from '@/analytics';

import Sadface from '@/assets/iconography/sadface.svg';

import Spinner from '@/components/Spinner';
import NotFound from '@/pages/NotFound';
import CheckoutPaymentSummary from '@/components/CheckoutPaymentSummary';

import './index.css';

class CancelBooking extends Component {
  state = {
    loading: true,
    attemptCancel: false
  }
  componentDidMount = async () => {
    initializeAdTracking(this.props.location.pathname);
    let session, activity, partner, address, bookings = [], refund, tax
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY);
    const SESSION_RESPONSE = await fetch(`/api/sessions/${this.props.match.params.sessionID}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin'
      }
    });
    const BOOKING_RESPONSE = await fetch(`/api/bookings/${this.props.match.params.sessionID}`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      }
    });
    const SESSION_DATA = await SESSION_RESPONSE.json();
    const BOOKING_DATA = await BOOKING_RESPONSE.json();
    if(SESSION_DATA.auth) {
      session = SESSION_DATA.session
      activity = SESSION_DATA.activity
      partner = SESSION_DATA.partner
      if(activity.address) {
        address = (activity.address.addressLine2 ? activity.address.addressLine2 + ' - ' : '') + activity.address.addressLine1 + ', ' + activity.address.city + ', ' + activity.address.postalCode
      } else {
        address = (partner.address.addressLine2 ? partner.address.addressLine2 + ' - ' : '') + partner.address.addressLine1 + ', ' + partner.address.city + ', ' + partner.address.postalCode
      }
    }

    if(BOOKING_DATA.auth) {
      bookings = BOOKING_DATA.bookings.filter(booking => booking.status === 'ATTEND' && booking.type !== 'locked')
    }

    const diff = session.startDate - moment().unix();
    if(session.cancellationNotice === -1 || activity.cancellationNotice === -1) {
      refund = 0
    } else if(bookings[0] && bookings[0].type === 'term' && diff > 0 && (session.cancellationNotice === 0 || session.cancellationNotice * 86400 < diff)) {
      refund = session.costInCents / 100
    } else if (bookings[0] && bookings[0].type === 'drop-in' && diff > 0 && (activity.cancellationNotice === 0 || activity.cancellationNotice * 86400 < diff)) {
      refund = activity.costInCents / 100
    } else {
      refund = 0
    }

    if(activity.taxable) {
      tax = refund * activity.taxRate
    }

    await this.setState({
      session,
      activity,
      partner,
      address,
      diff,
      bookings,
      refund,
      tax,
      loading: false
    })
  }
  handleCancel = async (ev) => {
    ev.preventDefault();
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY);
    this.setState({
      attemptCancel: true
    });
    if(!this.state.bookingID) {
      this.setState({
        error: 'Select a booking you\'d like to cancel!',
        attemptCancel: false
      });
    } else {
      const BOOKING_CANCEL_RESPONSE = await fetch('/api/bookings/cancel', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + DEMO_TOKEN,
          'x-access-token': DEMO_TOKEN,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': 'Origin'
        },
        body: JSON.stringify({
          itemID: this.state.session._id,
          bookingID: this.state.bookingID,
        })
      });

      const BOOKING_CANCEL_DATA = await BOOKING_CANCEL_RESPONSE.json();
      if(BOOKING_CANCEL_DATA.auth) {
        this.props.history.push(`/cancel/${this.props.match.params.sessionID}/success`);
      } else {
        this.setState({
          attemptCancel: false,
          error: BOOKING_CANCEL_DATA.error
        });
      }
    }
  }
  render() {
    if(this.state.loading || this.state.attemptCancel) {
      return <Spinner />
    }
    if(!this.state.session) {
      return <NotFound />
    }
    if(this.state.bookings.length <= 0) {
      return (
        <div className = 'bookingConfirmationContainer'>
          <div className = 'paymentConfirmationCheckmarkContainer'>
            <img
              className = 'paymentConfirmationCheckmark'
              src = {Sadface}
              alt = 'Find new children activities'
            />
          </div>
          <div className = 'bookingConfirmationHeaderContainer'>
            <h1>You don't have a booking for this class!</h1>
            <span className = 'defaultText'>It doesn't look like you have a booking for <strong>{this.state.activity.name}</strong> at this time.</span>
            <br />
            <span className = 'defaultText'>If you think this is a mistake, please contact support!</span>
          </div>
          <div className = 'bookingConfirmationShareContainer'>
            <span className = 'defaultSubheaderText'>Find another activity!</span>
            <span className = 'defaultText'>Did you know the average parent takes their children to 9 activities per month?</span>
            <NavLink className = 'bookingReengageNav' to = '/activities'>
              <button>
                Book another activity!
              </button>
            </NavLink>
          </div>
        </div>
      );
    }
    if(this.state.diff <= 0) {
      return (
        <div className = 'bookingConfirmationContainer'>
          <div className = 'paymentConfirmationCheckmarkContainer'>
            <img
              className = 'paymentConfirmationCheckmark'
              src = {Sadface}
              alt = 'Find new children activities'
            />
          </div>
          <div className = 'bookingConfirmationHeaderContainer'>
            <h1>This class already passed!</h1>
            <span className = 'defaultText'>You can't cancel this class because it already passed.</span>
          </div>
          <div className = 'bookingConfirmationShareContainer'>
            <span className = 'defaultSubheaderText'>Find another activity!</span>
            <span className = 'defaultText'>Did you know the average parent takes their children to 9 activities per month?</span>
            <NavLink className = 'bookingReengageNav' to = '/activities'>
              <button>
                Book another activity!
              </button>
            </NavLink>
          </div>
        </div>
      );
    }
    return (
      <div className = 'bookingConfirmationContainer'>
        <div className = 'checkoutContainer'>
          <div className = 'checkoutFocusSection'>
            <h1 className = 'defaultHeaderText'>Which booking would you like to cancel?</h1>
            <form onSubmit = {this.handleCancel}>
              <div className = 'formSection'>
                <select
                  onChange = {(e) => this.setState({ bookingID : e.target.selectedOptions[0].id })}>
                  <option selected disabled defaultValue> -- select a booking -- </option>
                  {
                    this.state.bookings.map(booking => {
                      return(
                        <option id = {booking.bookingID}>{booking.childDetails.firstName} {booking.childDetails.lastName} ({booking.bookingID})</option>
                      );
                    })
                  }
                </select>
              </div>
              <div className = 'formSection'>
                <span className = 'defaultErrorText'>{this.state.error}</span>
              </div>
              <button>Cancel class</button>
            </form>
          </div>
          <CheckoutPaymentSummary
            session = {this.state.session}
            bookings = {this.state.bookings}
            activity = {this.state.activity}
            partner = {this.state.partner}
            address = {this.state.address}
            refund = {this.state.refund}
            tax = {this.state.tax}
            cancel = {true}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(CancelBooking);