import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

import { initializeAdTracking } from '@/analytics'

import PostCard from '@/components/PostCard'
import Spinner from '@/components/Spinner'

import './index.css'

import getPosts from '@/posts'

export default class Blog extends Component {
  state = {
    posts: null
  }
  componentDidMount = async () => {
    initializeAdTracking(this.props.location.pathname);
    const posts = await getPosts()
    this.setState({ posts })
  }
  render() {
    if(this.state.posts == null){
      return <Spinner />
    }
    return (
      <div className = 'blogContainer'>
        {
          this.state.posts.map(post => {
            return (
              <PostCard post = {post} />
            )
          })
        }
        <div className = 'blogCTAContainer'>
          <h1 className = 'blogCTAHeader'>Find the perfect kids activity</h1>
          <h2 className = 'blogCTASubtitle'>Book drop-in activities for your child in your area.</h2>
          <div>
            <NavLink smooth to = '/activities'>
              <button className = 'blogCTA'>Browse classes!</button>
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}