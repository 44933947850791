import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '@/assets/logos/logo.svg';
import './index.css';

export default class Footer extends Component {
  render() {
    return (
      <div className = 'footerContainer'>
        <div className = 'footerColumnContainer'>
          <NavLink to = '/' className = 'footerHeaderText'>
            <img
              src = {Logo}
              alt = 'PlayPass Toronto Kid Activities'
              className = 'headerLogo'
            />
          </NavLink>
          <span className = 'footerDescriptionText'>PlayPass - All rights reserved. PlayPass helps you find, try, and book children's activities in Toronto. Discover new kid activities in Toronto!</span>
        </div>
        <div className = 'footerColumnContainer'>
          <span className = 'footerHeaderText'>Company</span>
          <a href = 'https://partners.playpass.ca' className = 'footerText'>Join as a Partner</a>
          <a href = 'mailto:hello@playpass.ca?subject=PlayPass Support' className = 'footerText'>Support</a>
        </div>
        <div className = 'footerColumnContainer'>
          <span className = 'footerHeaderText'>Terms</span>
          <NavLink to = '/terms-of-service' className = 'footerText'>Terms of Service</NavLink>
          <NavLink to = '/privacy-policy' className = 'footerText'>Privacy Policy</NavLink>
          <NavLink to = '/playpass-guarantee' className = 'footerText'>The PlayPass Guarantee</NavLink>
        </div>
      </div>
    );
  }
}