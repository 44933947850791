import React, { Component } from 'react';

import { initializeAdTracking } from '@/analytics';

import Hero from '@/components/Hero';
import WhyFamiliesLovePlayPass from '@/components/WhyFamiliesLovePlayPass';
import SplashFirstCTA from '@/components/SplashFirstCTA';
import Testimonial from '@/components/Testimonial';
import SplashProviders from '@/components/SplashProviders';
import HowItWorks from '@/components/HowItWorks';
import SplashSecondCTA from '@/components/SplashSecondCTA';
import SplashFinalCTA from '@/components/SplashFinalCTA';

import './index.css';

export default class Splash extends Component {
  componentDidMount = () => {
    initializeAdTracking(this.props.location.pathname);
  }
  render() {
    return (
      <div className = 'splashContainer'>
        <Hero />
        <WhyFamiliesLovePlayPass />
        <SplashFirstCTA />
        <Testimonial />
        <SplashProviders />
        <HowItWorks />
        <SplashSecondCTA />
        <SplashFinalCTA />
      </div>
    );
  }
}