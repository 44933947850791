import React, { Component } from 'react'
import moment from 'moment'

import { STORAGE_KEY } from '@/constants'

import ModalAddChild from '@/components/ModalAddChild'

// import './index.css';

export default class CheckoutChildSection extends Component {
  state = {
    addChildModal: false,
    children: [],
    error: null,
  }
  componentDidMount = async () => {
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY);
    const CHILD_RESPONSE = await fetch('/api/children', {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      }
    });
    const CHILD_DATA = await CHILD_RESPONSE.json();
    await this.setState({
      children: CHILD_DATA.children,
    });
  }
  handleCloseModal = () => {
    this.setState({
      addChildModal: false,
      error: null
    });
  }
  updateChild = () => {
    this.setState({
      addChildModal: true,
      error: null
    });
  }
  selectChild = async (selectedChild) => {
    if(this.props.user){
      const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY);
      const CHILD_RESPONSE = await fetch('/api/children', {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + DEMO_TOKEN,
          'x-access-token': DEMO_TOKEN
        }
      });
      const CHILD_DATA = await CHILD_RESPONSE.json();
      await this.setState({
        children: CHILD_DATA.children
      });
      await this.props.selectChild(this.state.children.find(child => child._id === selectedChild))
    } else {
      await this.props.selectChild(selectedChild)
    }
    this.handleCloseModal()
  }
  render() {
    return (
      <div className = 'checkoutFormSection'>
        {
          this.state.addChildModal &&
          <ModalAddChild
            handleCloseModal = {this.handleCloseModal}
            children = {this.state.children}
            selectChild = {this.selectChild}
            child = {this.props.child}
          />
        }
        <div className = 'checkoutHeaderSection'>
          <h2 className = 'checkoutHeaderText'>Child details</h2>
        </div>
        <div className = 'checkoutDetailSection'>
          {
            this.props.child ?
            (
              <div className = 'checkoutDetailFilled'>
                <span>{this.props.child.firstName} {this.props.child.lastName}</span>
                <span>{(moment.unix(this.props.child.birthday)).format('MMMM D YYYY')}</span>
                <span className = 'checkoutAddDetailsText' onClick = {this.updateChild}>Change child</span>
              </div>
            ) :
            (
              <div className = 'checkoutDetailFilled'>
                <span className = 'checkoutAddDetailsText' onClick = {this.updateChild}>Add child details</span>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}