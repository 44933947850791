import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import './index.css';

export default class Hero extends Component {
  render() {
    return (
      <div className = 'heroContainer'>
        <div className = 'heroSection'>
          <div className = 'heroPrimary'>
            <h1 className = 'heroHeader'>Discover their new <span className = 'heroHighlightText'>favourite<span className = 'heroHighlight'></span></span> activity</h1>
            <h2 className = 'heroSubheader'>PlayPass makes it easy to find and book kids activities from newborn to 12 years old</h2>
            <NavLink to = '/activities'>
              <button className = 'heroButton'>Browse 100+ activities</button>
            </NavLink>
          </div>
          <div className = 'heroSecondary'>
            <div className = 'heroBackground' />
          </div>
        </div>
      </div>
    );
  }
}