import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { STORAGE_KEY } from '@/constants';

import './index.css';

class FormProfile extends Component {
  state = {
    updating: false
  }
  componentDidMount = () => {
    if(this.props.user){
      this.setState({
        firstName: this.props.user.firstName,
        lastName: this.props.user.lastName,
        email: this.props.user.email,
        phone: this.props.user.phone
      });
    }
  }
  updateProfile = async (ev) => {
    ev.preventDefault();
    this.setState({
      error: null,
      success: false,
      updating: true
    });
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY);
    const UPDATE_RESPONSE = await fetch('/api/users/update/parent', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin',
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      },
      body: JSON.stringify({
        email: this.state.email,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        phone: this.state.phone
      })
    });
    const UPDATE_DATA = await UPDATE_RESPONSE.json();

    if(UPDATE_DATA.auth) {
      this.setState({
        success: true,
        error: null,
        updating: false
      });
    } else {
      this.setState({
        error: UPDATE_DATA.error,
        success: false,
        updating: false
      });
    }
    await this.props.updateUser();

    setTimeout(() => {
      this.setState({
        updating: false,
        success: false
      });
    }, 1500);
  }
  render() {
    return (
      <form className = 'formFieldContainer' onSubmit = {this.updateProfile}>
        <div className = 'formSection'>
          <label className = 'formLabel'>First Name</label>
          <input
            onChange = {(e) => this.setState({ firstName : e.target.value })}
            type = "text"
            placeholder = 'First name'
            value = {this.state.firstName}
          />
          <label className = 'formLabel'>Last Name</label>
          <input
            onChange = {(e) => this.setState({ lastName : e.target.value })}
            type = "text"
            placeholder = 'Last name'
            value = {this.state.lastName}
          />
          <label className = 'formLabel'>Email</label>
          <input
            onChange = {(e) => this.setState({ email : e.target.value })}
            type = "text"
            placeholder = 'Email'
            value = {this.state.email}
          />
          <label className = 'formLabel'>Phone</label>
          <input
            onChange = {(e) => this.setState({ phone : e.target.value })}
            type = "tel"
            placeholder = 'Phone'
            value = {this.state.phone}
          />
        </div>

        {
          this.state.success ?
          (
            <div className = 'formSection'>
              <span className = 'formSuccessText'>Your information was successfully updated.</span>
              <button className = 'formSuccessProfileButton' disabled>Success!</button>
            </div>
          ) : this.state.updating ?
          (
            <div className = 'formSection'>
              <button disabled>Updating...</button>
            </div>
          ) :
          (
            <div className = 'formSection'>
              <span className = 'defaultErrorText'>{this.state.error}</span>
              <button>Update</button>
            </div>
          )
        }
      </form>
    );
  }
}

export default withRouter(FormProfile);