import React, { Component } from 'react';
import { HashLoader } from 'react-spinners';

import './index.css';

export default class Spinner extends Component {
  render() {
    return (
      <div className = 'hashContainer'>
        <HashLoader
          color = {'#5BC0EB'}
          size = {60}
        />
      </div>
    );
  }
}