import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Spinner from '@/components/Spinner';

import './index.css';

class FormLogin extends Component {
  state = {
    error: null,
    email: null,
    password: null,
    loggingIn: false
  }
  login = async (ev) => {
    ev.preventDefault();
    this.setState({
      error: null,
      loggingIn: true,
    });
    if(!this.state.email || !this.state.password) {
      this.setState({
        error: 'Please enter all required fields',
        loggingIn: false
      });
    } else {
      const LOGIN_RESPONSE = await fetch('/api/users/login/parent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': 'Origin'
        },
        body: JSON.stringify({
          email: this.state.email,
          password: this.state.password
        })
      });

      const LOGIN_DATA = await LOGIN_RESPONSE.json();

      if(LOGIN_DATA.auth) {
        await this.props.handleLogin(LOGIN_DATA.authToken);
      } else {
        this.setState({
          error: LOGIN_DATA.error,
          loggingIn: false
        });
      }
    }
  }
  render() {
    if(this.state.loggingIn){
      return <Spinner />
    }
    return (
      <form className = 'formFieldContainer' onSubmit = {this.login}>
        <div className = 'formSection'>
          <input
            onChange = {(e) => this.setState({ email : e.target.value })}
            type = "email"
            placeholder = 'Email address'
            value = {this.state.email}
          />
          <input
            onChange = {(e) => this.setState({ password : e.target.value })}
            type = "password"
            placeholder = 'Password'
            value = {this.state.password}
          />
        </div>

        <div className = 'formSection'>
          <span className = 'defaultErrorText'>{this.state.error}</span>
        </div>
        <button>
          Login
        </button>
      </form>
    );
  }
}

export default withRouter(FormLogin);