import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import FormProfile from '@/components/FormProfile';
import FormSignup from '@/components/FormSignup';

import './index.css';

class ModalAddDetails extends Component {
  render() {
    if(!this.props.user){
      return (
        <div>
          <div className = 'overlay' />
          <div className = 'modalContainer'>
            <div className = 'modalSection'>
              <div className = 'close' onClick = {() => {this.props.handleCloseModal()}}/>
              <h2 className = 'checkoutHeaderText'>Add your contact details!</h2>
              <FormSignup
                redirectOnSignup = {this.props.redirectOnSignup}
              />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className = 'overlay' />
        <div className = 'modalContainer'>
          <div className = 'modalSection'>
            <div className = 'close' onClick = {() => {this.props.handleCloseModal()}}/>
            <h2 className = 'checkoutHeaderText'>Update your information</h2>
            <FormProfile
              user = {this.props.user}
              updateUser = {this.props.updateUser}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ModalAddDetails);