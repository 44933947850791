import React, { Component } from 'react';

import Celebration from '@/assets/iconography/celebration.svg';
import Checklist from '@/assets/iconography/checklist.svg';
import Diamond from '@/assets/iconography/diamond.svg';
import Lightbulb from '@/assets/iconography/lightbulb.svg';

import './index.css';

export default class WhyFamiliesLovePlayPass extends Component {
  render() {
    return (
      <div className = 'valuePropContainer'>
        <div className = 'valuePropSection'>
          <h1 className = 'valuePropHeader'>Why families love PlayPass</h1>
          <div className = 'valuePropBenefitsContainer'>
            <div className = 'valuePropenefitsItem'>
              <img
                src = {Lightbulb}
                className = 'valuePropBenefitsIcon'
                alt = 'PlayPass helps you be more active through kids classes'
              />
              <h2 className = 'valuePropBenefitsText'>Find New Classes</h2>
            </div>
            <div className = 'valuePropBenefitsItem'>
              <img
                src = {Checklist}
                className = 'valuePropBenefitsIcon'
                alt = 'PlayPass helps you be more active through kids classes'
              />
              <h2 className = 'valuePropBenefitsText'>FAQs About Classes</h2>
            </div>
            <div className = 'valuePropBenefitsItem'>
              <img
                src = {Celebration}
                className = 'valuePropBenefitsIcon'
                alt = 'PlayPass helps you be more active through kids classes'
              />
              <h2 className = 'valuePropBenefitsText'>Try With Drop-ins</h2>
            </div>
            <div className = 'valuePropBenefitsItem'>
              <img
                src = {Diamond}
                className = 'valuePropBenefitsIcon'
                alt = 'PlayPass helps you be more active through kids classes'
              />
              <h2 className = 'valuePropBenefitsText'>Save Money</h2>
            </div>
          </div>
          <div className = 'valuePropSection'>
            <div className = 'valuePropTestimonial'>
              <h1 className = 'valuePropSubheader'>"PlayPass helps me know what to expect by sharing info like stroller parking, what to wear, and what to bring"</h1>
            </div>
            <div className = 'valuePropParent'>
              <h2 className = 'valuePropSubtitle'>– Katie, mother of Ben (12 months)</h2>
            </div>
          </div>
        </div>
      </div>
    );
  }
}