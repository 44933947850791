import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { initializeAdTracking } from '@/analytics';

import Spinner from '@/components/Spinner';

class Logout extends Component {
  componentDidMount = async () => {
    initializeAdTracking(this.props.location.pathname);
    await this.props.resetToken();
  }
  render() {
    return <Spinner />
  }
}

export default withRouter(Logout);