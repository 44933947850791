import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import LazyLoad from 'react-lazy-load';
import ClampLines from 'react-clamp-lines';

import './index.css';

export default class CardActivity extends Component {
  render() {
    return (
      <div className = 'cardContainer'>
        <NavLink className = 'cardNavLink' to = {`/${this.props.activity.partner.slug}/${this.props.activity.activity.slug}`}>
          <div className = 'cardSection'>
            <LazyLoad offsetVertical = {800}>
              <img
                className = 'cardImage'
                src = {this.props.activity.activity.activityImage}
                alt = 'PlayPass Partner'
              />
            </LazyLoad>
          </div>
          <div className = 'cardSection'>
            <h1 className = 'cardHeader'>{this.props.activity.activity.name}</h1>
            <span className = 'cardSubtitle'>{this.props.activity.partner.name} · {this.props.activity.activity.ageRange[0]} – {this.props.activity.activity.ageRange[1] === 100 ? '12+' : this.props.activity.activity.ageRange[1]} years old · {this.props.activity.activity.address ? this.props.activity.activity.address.neighbourhood : this.props.activity.partner.address.neighbourhood}</span>
          </div>
          <div className = 'cardSection'>
            <div className = 'cardDescriptionContainer'>
              <ClampLines
                text = {this.props.activity.activity.description}
                lines = {3}
                ellipsis = '...'
                buttons = {false}
                className = 'cardDescription'
              />
            </div>
          </div>
        </NavLink>
      </div>
    );
  }
}