import React, { Component } from 'react';

import { initializeAdTracking } from '@/analytics';

import Spinner from '@/components/Spinner'
import FormSetPassword from '@/components/FormSetPassword';

export default class SetPassword extends Component {
  state = {
    loading: true
  }
  componentDidMount = async () => {
    initializeAdTracking(this.props.location.pathname);
    let params = await new URLSearchParams(this.props.history.location.search)
    const token = params.get('token')
    this.setState({
      token: token,
      loading: false
    });
  }
  render() {
    if(this.state.loading){
      return <Spinner />
    }
    return (
      <div className = 'pageContainer'>
        <div className = 'resetPasswordSection'>
          <span className = 'formHeader'>Choose a new password</span>
          <span className = 'text'>Password must be at least 6 characters long.</span>
        </div>
        <FormSetPassword
          token = {this.state.token}
          handleLogin = {this.props.handleLogin}
        />
      </div>
    );
  }
}