import React, { Component } from 'react';
import { Elements } from 'react-stripe-elements';

import FormCheckout from '@/components/FormCheckout';

export default class CheckoutElements extends Component {
  render() {
    return (
      <Elements>
        <FormCheckout
          updateName = {this.props.updateName}
          bookClass = {this.props.bookClass}
          handleAttemptPayment = {this.props.handleAttemptPayment}
          disablePaymentButton = {this.props.disablePaymentButton}
          name = {this.props.name}
          disableButton = {this.props.disableButton}
          showCreditCardFields = {this.props.showCreditCardFields}
          error = {this.props.error}
        />
      </Elements>
    );
  }
}
