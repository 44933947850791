import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import LazyLoad from 'react-lazy-load'

import { STORAGE_KEY } from '@/constants'
import { initializeAdTracking } from '@/analytics'

import Checkmark from '@/assets/iconography/checkmark.svg'
import StarFilled from '@/assets/iconography/starFilled.svg'

import Spinner from '@/components/Spinner'
import MapView from '@/components/MapView'
import Mapper from '@/components/Mapper'
import SelectedSession from '@/components/SelectedSession'
import NotFound from '@/pages/NotFound'

import './index.css';

export default class Activity extends Component {
  state = {
    loading: true,
    seeMore: false,
    bookings: [],
    attendees: [],
  }
  componentDidMount = async () => {
    initializeAdTracking(this.props.location.pathname);
    await this.props.updateUser();
    let SESSION_RESPONSE, SESSION_DATA, ATTENDANCE_RESPONSE, ATTENDANCE_DATA
    const partnerSlug = this.props.match.params.partnerSlug
    const activitySlug = this.props.match.params.activitySlug
    const sessionID = this.props.match.params.sessionID

    if(partnerSlug && activitySlug && sessionID) {
      SESSION_RESPONSE = await fetch(`/api/sessions/slug/${partnerSlug}/${activitySlug}/${sessionID}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': 'Origin'
        }
      });
      SESSION_DATA = await SESSION_RESPONSE.json();
    } else if(sessionID) {
      SESSION_RESPONSE = await fetch(`/api/sessions/${sessionID}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': 'Origin'
        }
      });
      SESSION_DATA = await SESSION_RESPONSE.json();
    }
    if(sessionID) {
      ATTENDANCE_RESPONSE = await fetch(`/api/sessions/attending/${sessionID}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': 'Origin'
        }
      });
      ATTENDANCE_DATA = await ATTENDANCE_RESPONSE.json();
      this.setState({
        attendees: ATTENDANCE_DATA.attending
      })
    }

    if(SESSION_DATA && SESSION_DATA.auth) {
      this.setState({
        session: SESSION_DATA.session,
        activity: SESSION_DATA.activity,
        partner: SESSION_DATA.partner,
      });
      const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY);
      if(DEMO_TOKEN && this.state.session) {
        const BOOKING_RESPONSE = await fetch(`/api/bookings/${this.state.session._id}`, {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + DEMO_TOKEN,
            'x-access-token': DEMO_TOKEN
          }
        });
        const BOOKING_DATA = await BOOKING_RESPONSE.json();
        if(BOOKING_DATA.auth) {
          this.setState({
            bookings: BOOKING_DATA.bookings.filter(booking => booking.status === 'ATTEND' && booking.type !== 'locked')
          });
        }
      }
    } else {
      const ACTIVITY_RESPONSE = await fetch(`/api/activities/${partnerSlug}/${activitySlug}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': 'Origin'
        }
      });
      const ACTIVITY_DATA = await ACTIVITY_RESPONSE.json();
      if(ACTIVITY_DATA.auth) {
        this.setState({
          activity: ACTIVITY_DATA.activity,
          partner: ACTIVITY_DATA.partner
        });
      }
    }

    if(this.state.activity) {
      if(this.state.activity.address) {
        this.setState({
          address: (this.state.activity.address.addressLine2 ? this.state.activity.address.addressLine2 + ' - ' : '') + this.state.activity.address.addressLine1 + ', ' + this.state.activity.address.city + ', ' + this.state.activity.address.postalCode
        });
      } else {
        this.setState({
          address: (this.state.partner.address.addressLine2 ? this.state.partner.address.addressLine2 + ' - ' : '') + this.state.partner.address.addressLine1 + ', ' + this.state.partner.address.city + ', ' + this.state.partner.address.postalCode
        });
      }

      const ITEM_RESPONSE = await fetch(`/api/sessions/upcoming/${this.state.activity._id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': 'Origin'
        }
      });
      const ITEM_DATA = await ITEM_RESPONSE.json();
      if(ITEM_DATA.auth) {
        this.setState({
          seeMore: false,
          allDropins: ITEM_DATA.sessions,
          dropins: ITEM_DATA.sessions.length > 5 ? ITEM_DATA.sessions.slice(0, 5) : ITEM_DATA.sessions,
          terms: ITEM_DATA.semesters
        });
      }
    }
    this.setState({
      loading: false
    });
  }
  toggleSeeMore = async () => {
    await this.setState({
      seeMore: this.state.seeMore ? false : true
    });
    if(this.state.seeMore) {
      this.setState({
        dropins: this.state.allDropins
      });
    } else {
      this.setState({
        dropins: this.state.allDropins.slice(0, 5)
      });
    }
  }
  render() {
    if(this.state.loading){
      return <Spinner />
    }
    if(!this.state.loading && !this.state.activity) {
      return <NotFound />
    }
    return (
      <div className = 'activityContainer'>
        {
          this.props.global.user &&
          <div className = 'activitiesHeader'>
            <div className = 'activitiesCreditBox'>
              <span className = 'activitiesCreditText'>You have ${(this.props.global.user.accountBalanceInCents/100).toFixed(2)} left</span>
            </div>
          </div>
        }
        <div className = 'activitySection'>
          <div className = 'activityHeaderContainer'>
            <div className = 'activityHeaderBox'>
              <span className = 'defaultHeaderText'>{this.state.activity.name}</span>
              <span className = 'defaultSubheaderText'>{this.state.activity.ageRange[0] === 100 ? '12+' : this.state.activity.ageRange[0]} – {this.state.activity.ageRange[1] === 100 ? '12+' : this.state.activity.ageRange[1]} years old</span>
              <span className = 'defaultSubheaderText'>By: {this.state.partner.name}</span>
              <div>
                <img
                  className = 'cardIcon'
                  src = {StarFilled}
                  alt = 'PlayPass Activity Age Range'
                />
                <img
                  className = 'cardIcon'
                  src = {StarFilled}
                  alt = 'PlayPass Activity Age Range'
                />
                <img
                  className = 'cardIcon'
                  src = {StarFilled}
                  alt = 'PlayPass Activity Age Range'
                />
                <img
                  className = 'cardIcon'
                  src = {StarFilled}
                  alt = 'PlayPass Activity Age Range'
                />
                <img
                  className = 'cardIcon'
                  src = {StarFilled}
                  alt = 'PlayPass Activity Age Range'
                />
              </div>
              <br />
              <span className = 'defaultText activityDescriptionText'>{this.state.activity.description}</span>
            </div>
            <LazyLoad offsetVertical = {600} className = 'activityHeaderImageContainer'>
              <img
                className = 'activityHeaderImage'
                src = {this.state.activity.activityImage}
                alt = {this.state.activity.name}
              />
            </LazyLoad>
          </div>
        </div>
        {
          this.state.session &&
          <SelectedSession
            session = {this.state.session}
            activity = {this.state.activity}
          />
        }

        <div className = 'activitySection'>
          <span className = 'defaultSubheaderText'>All upcoming classes</span>
          {
            this.state.terms && this.state.terms.length >= 1 ?
            (
              <div className = 'activityDropInContainer'>
                <span className = 'defaultBoldText'>Full Term</span>
                <Mapper
                  content = {this.state.terms}
                  type = 'lineSemester'
                />
              </div>
            ) :
            (
              <div />
            )
          }
          {
            this.state.dropins && this.state.dropins.length >= 1 ?
            (
              <div className = 'activityDropInContainer'>
                <span className = 'defaultBoldText'>Drop-ins</span>
                <div className = 'activityBoxHeader'>
                  <span className = 'defaultBoldText'>${(this.state.activity.costInCents/100).toFixed(2)}</span>
                </div>
                <Mapper
                  content = {this.state.dropins}
                  type = 'lineSession'
                />
                {
                  this.state.allDropins.length > 5 && !this.state.seeMore ?
                  (
                    <div className = 'activitySeeMoreContainer'>
                      <span className = 'activitySeeMoreText' onClick = {() => this.toggleSeeMore()}>See All Classes</span>
                    </div>
                  ) : this.state.allDropins.length > 5 && this.state.seeMore ?
                  (
                    <div className = 'activitySeeMoreContainer'>
                      <span className = 'activitySeeMoreText' onClick = {() => this.toggleSeeMore()}>See Less</span>
                    </div>
                  ) :
                  (
                    <div />
                  )
                }
              </div>
            ) :
            (
              <div />
            )
          }
          {
            this.state.dropins && this.state.dropins.length <= 0 && this.state.terms && this.state.terms.length <= 0 ?
            (
              <div className = 'activityPreparationInfoSection'>
                <div className = 'activityDropInContainer'>
                  <span className = 'defaultErrorText'>There's no upcoming sessions for this activity!</span>
                </div>
                <div>
                  <NavLink to = {`/${this.state.partner.slug}`} className = 'defaultText'>See other activities by {this.state.partner.name}</NavLink>
                </div>
              </div>
            ) :
            (
              <div />
            )
          }
          <div className = 'activityPreparationInfoSection'>
            <span className = 'defaultText'>Always pay the lowest price with the PlayPass Guarantee.</span>
          </div>
          <div className = 'activityPreparationInfoSection'>
            <span className = 'defaultBoldText'>Refund policy</span>
            {
              this.state.activity.cancellationNotice === 1 ?
              (
                <div className = 'activityPreparationInfoContainer'>
                  <span className = 'defaultText'>You will receive a full refund if you cancel within 1 day of the activity start time.</span>
                </div>
              ) : this.state.activity.cancellationNotice > 0 ?
              (
                <div className = 'activityPreparationInfoContainer'>
                  <span className = 'defaultText'>You will receive a full refund if you cancel within {this.state.activity.cancellationNotice} days of the activity start time.</span>
                </div>
              ) : this.state.activity.cancellationNotice === -1 ?
              (
                <div className = 'activityPreparationInfoContainer'>
                  <span className = 'defaultText'>{this.state.partner.name} does not offer refunds for this class.</span>
                </div>
              ) :
              (
                <div className = 'activityPreparationInfoContainer'>
                  <span className = 'defaultText'>You will receive a full refund if you cancel before the activity begins.</span>
                </div>
              )
            }
          </div>
        </div>

        <div className = 'activitySection'>
          <span className = 'defaultSubheaderText'>Everything you need to know</span>
          {
            this.state.activity.preparationInfo && this.state.activity.preparationInfo[0] && this.state.activity.preparationInfo[0].length >= 1 ?
            (
              <div className = 'activityPreparationInfoSection'>
                <span className = 'defaultBoldText'>About the venue</span>
                {
                  this.state.activity.preparationInfo[0].map(info => {
                    return (
                      <div className = 'activityPreparationInfoContainer'>
                        <img
                          className = 'activityPreparationInfoCheckmark'
                          src = {Checkmark}
                          alt = 'PlayPass kids activity information'
                        />
                        <span className = 'defaultText'>{info}</span>
                      </div>
                    );
                  })
                }
              </div>
            ) :
            (
              <div />
            )
          }
          {
            this.state.activity.preparationInfo && this.state.activity.preparationInfo[1] && this.state.activity.preparationInfo[1].length >= 1 ?
            (
              <div className = 'activityPreparationInfoSection'>
                <span className = 'defaultBoldText'>Before attending</span>
                {
                  this.state.activity.preparationInfo[1].map(info => {
                    return (
                      <div className = 'activityPreparationInfoContainer'>
                        <img
                          className = 'activityPreparationInfoCheckmark'
                          src = {Checkmark}
                          alt = 'PlayPass kids activity information'
                        />
                        <span className = 'defaultText'>{info}</span>
                      </div>
                    );
                  })
                }
              </div>
            ) :
            (
              <div />
            )
          }
          {
            this.state.activity.preparationInfo && this.state.activity.preparationInfo[2] && this.state.activity.preparationInfo[2].length >= 1 ?
            (
              <div className = 'activityPreparationInfoSection'>
                <span className = 'defaultBoldText'>During the session</span>
                {
                  this.state.activity.preparationInfo[2].map(info => {
                    return (
                      <div className = 'activityPreparationInfoContainer'>
                        <img
                          className = 'activityPreparationInfoCheckmark'
                          src = {Checkmark}
                          alt = 'PlayPass kids activity information'
                        />
                        <span className = 'defaultText'>{info}</span>
                      </div>
                    );
                  })
                }
              </div>
            ) :
            (
              <div />
            )
          }
        </div>

        <div className = 'activitySection'>
          <span className = 'defaultSubheaderText'>Getting there</span>
          <LazyLoad offsetVertical = {600} className = 'activityMapContainer'>
            <MapView
              content = {this.state.address}
              type = 'provider'
            />
          </LazyLoad>
          <span className = 'defaultBoldText'>{this.state.activity.address ? 'Hosted at:' : this.state.partner.name}</span>
          <span className = 'defaultText'>{this.state.address}</span>
        </div>

        <div className = 'activitySection'>
          <span className = 'defaultSubheaderText'>About {this.state.partner.name}</span>
          <span className = 'defaultText activityDescriptionText'>{this.state.partner.description}</span>
          <br/>
          <div>
            <NavLink to = {`/${this.state.partner.slug}`} className = 'activitySeeMoreText'>See more activities by {this.state.partner.name}</NavLink>
          </div>
        </div>
      </div>
    );
  }
}