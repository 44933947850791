import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import { initializeAdTracking } from '@/analytics';

import FormLogin from '@/components/FormLogin';

import './index.css';

class Login extends Component {
  componentDidMount = () => {
    initializeAdTracking(this.props.location.pathname);
  }
  render() {
    return (
      <div className = 'loginContainer'>
        <h1 className = 'formHeaderText'>Login</h1>
        <FormLogin
          handleLogin = {this.props.handleLogin}
        />
        <NavLink to = '/reset-password' className = 'formSection'>
          <span className = 'defaultPromptText'>Forgot Password?</span>
        </NavLink>
      </div>
    );
  }
}

export default withRouter(Login);