import React, { Component } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import ChevronUp from '@/assets/iconography/global/chevron-up.svg'
import ChevronDown from '@/assets/iconography/global/chevron-down.svg'
import Search from '@/assets/iconography/global/search.svg'

import 'react-datepicker/dist/react-datepicker.css';
import './index.css';

export default class ActivitiesFilter extends Component {
  state = {
    loading: true
  }
  componentDidMount = () => {
    this.setState({
      loading: false
    });
  }
  toggleAge = async () => {
    this.setState({
      toggleAge: this.state.toggleAge ? false : true,
      toggleDate: false,
      toggleCategory: false
    });
    await this.toggleDatePicker();
  }
  toggleCategory = async () => {
    this.setState({
      toggleCategory: this.state.toggleCategory ? false : true,
      toggleDate: false,
      toggleAge: false
    });
    await this.toggleDatePicker();
  }
  toggleDate = async () => {
    this.setState({
      toggleDate: this.state.toggleDate ? false : true,
      toggleCategory: false,
      toggleAge: false
    });
    await this.toggleDatePicker();
  }
  toggleDatePicker = async () => {
    const { datepicker } = this.refs;
    if(datepicker){
      await datepicker.setOpen(this.state.toggleDate);
    }
  }
  setAge = (age) => {
    this.props.setAge(age);
    this.toggleAge();
  }
  setCategory = (category) => {
    this.props.setCategory(category);
    this.toggleCategory();
  }
  setDate = async (date) => {
    await this.props.setDate(date);
    this.setState({
      toggleDate: false
    });
    await this.toggleDatePicker();
  }
  render() {
    if(this.state.loading){
      return <div />
    }
    else {
      return (
        <div className = 'filterContainer'>
          {
            this.props.discovering ?
            (
              <div />
            ) :
            (
              <div className = 'filterHeaderContainer'>
                <h1 className = 'filterHeaderText'>Discover East Toronto</h1>
              </div>
            )
          }
          <div className = 'filterOptionsSection'>
            <div className = 'filterOptionsLeftContainer'>
              <div className = 'filterOptionsItem' onClick = {() => this.toggleAge()}>
                {
                  this.state.toggleAge ?
                  (
                    <div className = 'filterOptionSelected'>
                      {this.props.age === 0 || this.props.age === '0' ? 'Under 1' : this.props.age}
                      <img
                        src = {ChevronUp}
                        className = 'basicIcon'
                        alt = 'PlayPass the best way to find kids activities'
                      />
                    </div>
                  ) :
                  (
                    <div className = 'filterOptionSelected'>
                      {this.props.age === 0 || this.props.age === '0' ? 'Under 1' : this.props.age}
                      <img
                        src = {ChevronDown}
                        className = 'basicIcon'
                        alt = 'PlayPass the best way to find kids activities'
                      />
                    </div>
                  )
                }
              </div>
              {
                this.state.toggleAge ?
                (
                  <div className = 'filterDropdown'>
                    <div className = {this.props.age === 'All ages' ? 'filterDropdownItemSelected' : 'filterDropdownItem'} onClick = {() => this.setAge('All ages')}>All ages</div>
                    <div className = {this.props.age === 0 ? 'filterDropdownItemSelected' : 'filterDropdownItem'} onClick = {() => this.setAge(0)}>Under 1</div>
                    <div className = {this.props.age === 1 ? 'filterDropdownItemSelected' : 'filterDropdownItem'} onClick = {() => this.setAge(1)}>1</div>
                    <div className = {this.props.age === 2 ? 'filterDropdownItemSelected' : 'filterDropdownItem'} onClick = {() => this.setAge(2)}>2</div>
                    <div className = {this.props.age === 3 ? 'filterDropdownItemSelected' : 'filterDropdownItem'} onClick = {() => this.setAge(3)}>3</div>
                    <div className = {this.props.age === 4 ? 'filterDropdownItemSelected' : 'filterDropdownItem'} onClick = {() => this.setAge(4)}>4</div>
                    <div className = {this.props.age === 5 ? 'filterDropdownItemSelected' : 'filterDropdownItem'} onClick = {() => this.setAge(5)}>5</div>
                    <div className = {this.props.age === 6 ? 'filterDropdownItemSelected' : 'filterDropdownItem'} onClick = {() => this.setAge(6)}>6</div>
                    <div className = {this.props.age === 7 ? 'filterDropdownItemSelected' : 'filterDropdownItem'} onClick = {() => this.setAge(7)}>7</div>
                    <div className = {this.props.age === 8 ? 'filterDropdownItemSelected' : 'filterDropdownItem'} onClick = {() => this.setAge(8)}>8</div>
                    <div className = {this.props.age === 9 ? 'filterDropdownItemSelected' : 'filterDropdownItem'} onClick = {() => this.setAge(9)}>9+</div>
                  </div>
                ) :
                (
                  <div />
                )
              }
            </div>
            <div className = 'filterOptionsContainer'>
              <div className = 'filterOptionsItem' onClick = {() => this.toggleCategory()}>
                {
                  this.state.toggleCategory ?
                  (
                    <div className = 'filterOptionSelected'>
                      {this.props.category}
                      <img
                        src = {ChevronUp}
                        className = 'basicIcon'
                        alt = 'PlayPass the best way to find kids activities'
                      />
                    </div>
                  ) :
                  (
                    <div className = 'filterOptionSelected'>
                      {this.props.category}
                      <img
                        src = {ChevronDown}
                        className = 'basicIcon'
                        alt = 'PlayPass the best way to find kids activities'
                      />
                    </div>
                  )
                }
              </div>
              {
                this.state.toggleCategory ?
                (
                  <div className = 'filterDropdown'>
                    <div className = {this.props.category === 'All categories' ? 'filterDropdownItemSelected' : 'filterDropdownItem'} onClick = {() => this.setCategory('All categories')}>All categories</div>
                    <div className = {this.props.category === 'Arts & Crafts' ? 'filterDropdownItemSelected' : 'filterDropdownItem'} onClick = {() => this.setCategory('Arts & Crafts')}>Arts & Crafts</div>
                    <div className = {this.props.category === 'Baby & Toddler' ? 'filterDropdownItemSelected' : 'filterDropdownItem'} onClick = {() => this.setCategory('Baby & Toddler')}>Baby & Toddler</div>
                    <div className = {this.props.category === 'Creative' ? 'filterDropdownItemSelected' : 'filterDropdownItem'} onClick = {() => this.setCategory('Creative')}>Creative</div>
                    <div className = {this.props.category === 'Learning & Technology' ? 'filterDropdownItemSelected' : 'filterDropdownItem'} onClick = {() => this.setCategory('Learning & Technology')}>Learning & Technology</div>
                    <div className = {this.props.category === 'Music' ? 'filterDropdownItemSelected' : 'filterDropdownItem'} onClick = {() => this.setCategory('Music')}>Music</div>
                    <div className = {this.props.category === 'Play' ? 'filterDropdownItemSelected' : 'filterDropdownItem'} onClick = {() => this.setCategory('Play')}>Play</div>
                    <div className = {this.props.category === 'Sports & Fitness' ? 'filterDropdownItemSelected' : 'filterDropdownItem'} onClick = {() => this.setCategory('Sports & Fitness')}>Sports & Fitness</div>
                  </div>
                ) :
                (
                  <div />
                )
              }
            </div>
            <div className = 'filterOptionsContainer'>
              <div className = 'filterOptionsItem' onClick = {() => this.toggleDate()}>
                {
                  this.state.toggleDate ?
                  (
                    <div className = 'filterOptionSelected'>
                      {this.props.date === 'Any date' ? 'Any date' : moment(this.props.date).format('MMM. D, YYYY')}
                      <img
                        src = {ChevronUp}
                        className = 'basicIcon'
                        alt = 'PlayPass the best way to find kids activities'
                      />
                    </div>
                  ) :
                  (
                    <div className = 'filterOptionSelected'>
                      {this.props.date === 'Any date' ? 'Any date' : moment(this.props.date).format('MMM. D, YYYY')}
                      <img
                        src = {ChevronDown}
                        className = 'basicIcon'
                        alt = 'PlayPass the best way to find kids activities'
                      />
                    </div>
                  )
                }
              </div>
              {
                this.state.toggleDate ?
                (
                  <div className = 'filterDatePickerDropdown'>
                    <div className = {this.props.date === 'Any date' ? 'filterDropdownItemSelected' : 'filterDropdownItem'} onClick = {() => this.setDate('Any date')}>Any date</div>
                    <DatePicker
                      ref = 'datepicker'
                      selected = {this.props.date === 'Any date' ? moment().toDate() : this.props.date}
                      onChange = {date => {
                        this.setDate(date);
                        this.toggleDate();
                      }}
                      dateFormat = 'MMM. d, yyyy'
                      minDate = {moment().toDate()}
                      inline
                    />
                  </div>
                ) :
                (
                  <div />
                )
              }
            </div>
            <button className = 'filterButtonContainer' onClick = {() => this.props.onSearch()}>
              {
                this.props.discovering ?
                (
                  <span>Update search</span>
                ) :
                (
                  <span>Find a class</span>
                )
              }
            </button>
          </div>
          <div className = 'filterOptionsSectionMobile'>
            {
              this.props.discovering ?
              (
                <button className = 'filterButtonContainer' onClick = {() => this.props.toggleFilter()}>
                  <span className = 'filterSearchText'>{this.props.date === 'Any date' ? 'Any date' : moment(this.props.date).format('ddd., MMM D')} for {this.props.age === 0 || this.props.age === '0' ? 'under 1 years old' : this.props.age === 'All ages' ? 'all ages' : this.props.age + ' years old'} and {this.props.category}</span>
                  <img
                    src = {ChevronDown}
                    className = 'basicIcon'
                    alt = 'PlayPass the best way to find kids activities'
                  />
                </button>
              ) :
              (
                <button className = 'filterButtonContainer' onClick = {() => this.props.toggleFilter()}>
                  <img
                    src = {Search}
                    className = 'basicIcon'
                    alt = 'PlayPass the best way to find kids activities'
                  />
                  <span className = 'filterSearchText'>Find a class</span>
                  <img
                    src = {ChevronDown}
                    className = 'basicIcon'
                    alt = 'PlayPass the best way to find kids activities'
                  />
                </button>
              )
            }
          </div>
        </div>
      );
    }
  }
}