import React, { Component } from 'react';
import Geocode from "react-geocode";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

import Spinner from '@/components/Spinner';

import './index.css';

Geocode.setApiKey('AIzaSyApSjlzl_ztPffO3whaC49UrrK8Rus9Jyk');

const MapComponent = withScriptjs(withGoogleMap((props) =>
  props.locations ?
  (
    <GoogleMap
      defaultZoom = {props.locations.length > 1 ? 13 : 16}
      defaultCenter = {props.locations.length > 1 ? { lat: 43.675684, lng: -79.320443 } : { lat: props.locations[0].lat, lng: props.locations[0].lng }}
    >
      {
        props.locations.map(location => {
          return (
            <MarkerMap
              location = {location}
              key = {location}
            />
          );
        })
      }
    </GoogleMap>
  ) :
  (
    <GoogleMap
      defaultZoom = {13}
      defaultCenter = {{ lat: 43.675684, lng: -79.320443 }}
    />
  )
))

class MarkerMap extends Component {
  state = {
    loading: true
  }
  componentDidMount = async () => {
    this.setState({
      loading: false
    });
  }
  render(){
    if(this.state.loading) {
      return <Spinner />
    } else {
      return (
        <Marker
          position = {{ lat: this.props.location.lat, lng: this.props.location.lng }}
          key = {this.props.location}
        />
      );
    }
  }
}

export default class MapView extends Component {
  state = {
    loading: true
  }
  componentDidMount = async () => {
    if(this.props.type === 'providers' && this.props.content) {
      const locations = await Promise.all(this.props.content.map(async partner => {
        const GEOCODE_RESULTS = await Geocode.fromAddress(partner.address.addressLine1 + ', ' + partner.address.city + ', ' + partner.address.postalCode)
        return GEOCODE_RESULTS.results[0].geometry.location;
      }));
      this.setState({
        locations: locations
      });
    } else if(this.props.type === 'provider' && this.props.content) {
      const GEOCODE_RESULTS = await Geocode.fromAddress(this.props.content)
      const locations = await [GEOCODE_RESULTS.results[0].geometry.location];
      this.setState({
        locations: locations
      });
    } else {
      this.setState({
        locations: null
      });
    }
    this.setState({
      loading: false
    });
  }
  render(){
    if(this.state.loading){
      return <Spinner />
    }
    return (
      <div className = {this.props.type === 'providers' ? 'mapContainer' : 'pageAssetContainer'}>
        <MapComponent
          locations = {this.state.locations}
          googleMapURL = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyApSjlzl_ztPffO3whaC49UrrK8Rus9Jyk&v=3.exp&libraries=geometry,drawing,places'
          loadingElement = {<Spinner />}
          containerElement = {<div className = 'singleMapContainerElement' />}
          mapElement = {<div className = 'singleMapElement' />}
        />
      </div>
    );
  }
}