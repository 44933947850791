import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import LazyLoad from 'react-lazy-load';

import Ellipsis from '@/assets/iconography/ellipsis.svg';
import CreateArtStudio from '@/assets/logos/partners/create-art-studio.jpg';
import KidsFunTown from '@/assets/logos/partners/kids-fun-town.jpg';
import LittleRobotFriends from '@/assets/logos/partners/little-robot-friends.jpg';
import LocusKids from '@/assets/logos/partners/locus-kids.jpg';
import MiniNastics from '@/assets/logos/partners/mini-nastics.jpg';
import Pebbles from '@/assets/logos/partners/pebbles.jpg';
import RainbowSongs from '@/assets/logos/partners/rainbow-songs.jpg';
import TheMakeStation from '@/assets/logos/partners/the-make-station.jpg';

import './index.css';

export default class SplashProviders extends Component {
  render() {
    return (
      <div className = 'splashProviderContentSection'>
        <div className = 'splashProviderContentContainer'>
          <h1 className = 'splashProviderHeader'>PlayPass works with Toronto's best kid activity providers</h1>
          <div className = 'splashProviderPartnerSection'>
            <NavLink to = '/rainbow-songs' className = 'splashProviderPartnerItem splashProviderPartnerRainbowSongs'>
              <div className = 'splashProviderPartnerOpacity' />
              <LazyLoad offsetVertical = {600} className = 'splashProviderPartnerLogo' >
                <img
                  src = {RainbowSongs}
                  className = 'splashProviderPartnerLogo'
                  alt = 'Find kid activities in Toronto through PlayPass'
                />
              </LazyLoad>
              <span className = 'splashProviderPartnerTitle'>Rainbow Songs</span>
            </NavLink>
            <NavLink to = '/mini-nastics' className = 'splashProviderPartnerItem splashProviderPartnerMiniNastics'>
              <div className = 'splashProviderPartnerOpacity' />
              <LazyLoad offsetVertical = {600} className = 'splashProviderPartnerLogo'>
                <img
                  src = {MiniNastics}
                  className = 'splashProviderPartnerLogo'
                  alt = 'Find kid activities in Toronto through PlayPass'
                />
              </LazyLoad>
              <span className = 'splashProviderPartnerTitle'>Mini-Nastics</span>
            </NavLink>
            <NavLink to = '/the-make-station' className = 'splashProviderPartnerItem splashProviderPartnerTheMakeStation'>
              <div className = 'splashProviderPartnerOpacity' />
              <LazyLoad offsetVertical = {600} className = 'splashProviderPartnerLogo' >
                <img
                  src = {TheMakeStation}
                  className = 'splashProviderPartnerLogo'
                  alt = 'Find kid activities in Toronto through PlayPass'
                />
              </LazyLoad>
              <span className = 'splashProviderPartnerTitle'>The Make Station</span>
            </NavLink>
            <NavLink to = '/partners' className = 'splashProviderPartnerItemAllPartners'>
              <LazyLoad offsetVertical = {600} className = 'splashProviderPartnerLogo' >
                <img
                  src = {Ellipsis}
                  className = 'splashProviderPartnerLogo'
                  alt = 'Find kid activities in Toronto through PlayPass'
                />
              </LazyLoad>
              <span className = 'splashProviderPartnerTitle'>See All Partners</span>
            </NavLink>
          </div>

          <div className = 'splashProviderPartnerMiniSection'>
            <NavLink to = '/kids-fun-town' className = 'splashProviderPartnerMiniContainer'>
              <div className = 'splashProviderPartnerMiniItem splashProviderPartnerKidsFunTown'>
                <div className = 'splashProviderPartnerOpacity' />
                <LazyLoad offsetVertical = {600} className = 'splashProviderPartnerMiniLogo'>
                  <img
                    src = {KidsFunTown}
                    className = 'splashProviderPartnerMiniLogo'
                    alt = 'Find kid activities in Toronto through PlayPass'
                  />
                </LazyLoad>
              </div>
              <span className = 'splashProviderPartnerMiniTitle'>Kids Fun Town</span>
            </NavLink>
            <NavLink to = '/little-robot-friends' className = 'splashProviderPartnerMiniContainer'>
              <div className = 'splashProviderPartnerMiniItem splashProviderPartnerLittleRobotFriends'>
                <div className = 'splashProviderPartnerOpacity' />
                <LazyLoad offsetVertical = {600} className = 'splashProviderPartnerMiniLogo'>
                  <img
                    src = {LittleRobotFriends}
                    className = 'splashProviderPartnerMiniLogo'
                    alt = 'Find kid activities in Toronto through PlayPass'
                  />
                </LazyLoad>
              </div>
              <span className = 'splashProviderPartnerMiniTitle'>Little Robot Friends</span>
            </NavLink>
            <NavLink to = '/create-art-studio' className = 'splashProviderPartnerMiniContainer'>
              <div className = 'splashProviderPartnerMiniItem splashProviderPartnerCreateArtStudio'>
                <div className = 'splashProviderPartnerOpacity' />
                <LazyLoad offsetVertical = {600} className = 'splashProviderPartnerMiniLogo'>
                  <img
                    src = {CreateArtStudio}
                    className = 'splashProviderPartnerMiniLogo'
                    alt = 'Find kid activities in Toronto through PlayPass'
                  />
                </LazyLoad>
              </div>
              <span className = 'splashProviderPartnerMiniTitle'>Create Art Studio</span>
            </NavLink>
            <NavLink to = '/pebbles-play-based-learning-centre' className = 'splashProviderPartnerMiniContainer'>
              <div className = 'splashProviderPartnerMiniItem splashProviderPartnerPebbles'>
                <div className = 'splashProviderPartnerOpacity' />
                <LazyLoad offsetVertical = {600} className = 'splashProviderPartnerMiniLogo'>
                  <img
                    src = {Pebbles}
                    className = 'splashProviderPartnerMiniLogo'
                    alt = 'Find kid activities in Toronto through PlayPass'
                  />
                </LazyLoad>
              </div>
              <span className = 'splashProviderPartnerMiniTitle'>Pebbles</span>
            </NavLink>
            <NavLink to = '/locus-kids' className = 'splashProviderPartnerMiniContainer'>
              <div className = 'splashProviderPartnerMiniItem splashProviderPartnerLocusKids'>
                <div className = 'splashProviderPartnerOpacity' />
                <LazyLoad offsetVertical = {600} className = 'splashProviderPartnerMiniLogo'>
                  <img
                    src = {LocusKids}
                    className = 'splashProviderPartnerMiniLogo'
                    alt = 'Find kid activities in Toronto through PlayPass'
                  />
                </LazyLoad>
              </div>
              <span className = 'splashProviderPartnerMiniTitle'>Locus Kids</span>
            </NavLink>
            <NavLink to = '/partners' className = 'splashProviderPartnerMiniContainer'>
              <div className = 'splashProviderPartnerMiniItem splashProviderPartnerSeeAllPartners'>
                <LazyLoad offsetVertical = {600} className = 'splashProviderPartnerMiniLogo'>
                  <img
                    src = {Ellipsis}
                    className = 'splashProviderPartnerMiniLogo'
                    alt = 'Find kid activities in Toronto through PlayPass'
                  />
                </LazyLoad>
              </div>
              <span className = 'splashProviderPartnerMiniTitle'>See All Partners!</span>
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}