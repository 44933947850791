import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { initializeAdTracking } from '@/analytics';

import Checkmark from '@/assets/iconography/checkmark.svg';

import Spinner from '@/components/Spinner';
import NotFound from '@/pages/NotFound';

import './index.css';

export default class BookingConfirmation extends Component {
  state = {
    loading: true,
    shareLink: `https://playpass.ca/sh/${this.props.match.params.sessionID}`
  }
  componentDidMount = async () => {
    initializeAdTracking(this.props.location.pathname);
    const SESSION_RESPONSE = await fetch(`/api/sessions/${this.props.match.params.sessionID}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin'
      }
    });
    const SESSION_DATA = await SESSION_RESPONSE.json();
    if(SESSION_DATA.auth) {
      this.setState({
        session: SESSION_DATA.session,
        partner: SESSION_DATA.partner,
        activity: SESSION_DATA.activity,
        loading: false
      });
    } else {
      this.setState({
        loading: false
      });
    }
  }
  onCopy = () => {
    this.setState({
      copied: true
    });
    setTimeout(() => {
      this.setState({
        copied: false
      });
    }, 1000);
  }
  render() {
    if(this.state.loading) {
      return <Spinner />
    }
    if(!this.state.session) {
      return <NotFound />
    }
    return (
      <div className = 'bookingConfirmationContainer'>
        <div className = 'paymentConfirmationCheckmarkContainer'>
          <img
            className = 'paymentConfirmationCheckmark'
            src = {Checkmark}
            alt = 'Find new children activities'
          />
        </div>
        <div className = 'bookingConfirmationHeaderContainer'>
          <h1>Booking confirmed!</h1>
          <span className = 'defaultText'>Your booking for <strong>{this.state.activity.name}</strong> is confirmed!</span>
          <br />
          <span className = 'defaultText'>We've emailed you a copy of your booking confirmation. Just show up and show them your booking ID!</span>
        </div>
        <div className = 'bookingConfirmationShareContainer'>
          <span className = 'defaultSubheaderText'>Share with your friends!</span>
          <span className = 'defaultText'>Know friends who would also like this class? Share this link with them!</span>
          {
            this.state.copied ?
            (
              <div className = 'bookingShareContainerCopied'>
                <span className = 'bookingShareText'>playpass.ca/sh/{this.props.match.params.sessionID}</span>
                <img
                  className = 'bookingCopyTextCopied'
                  src = {Checkmark}
                  alt = 'PlayPass find new kid activities in Toronto'
                />
              </div>
            ) :
            (
              <div className = 'bookingShareContainer'>
                <span className = 'bookingShareText'>playpass.ca/sh/{this.props.match.params.sessionID}</span>
                <CopyToClipboard
                  onCopy = {this.onCopy}
                  text = {this.state.shareLink}
                >
                  <span className = 'bookingCopyText'>Copy</span>
                </CopyToClipboard>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}