import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import Checkmark from '@/assets/iconography/checkmark.svg';

import Spinner from '@/components/Spinner';

export default class FormResetPassword extends Component {
  state = {
    email: null,
    updating: false,
    error: null,
  }
  resetPassword = async (ev) => {
    ev.preventDefault();
    this.setState({
      error: null,
      updating: true,
    });
    if(!this.state.email) {
      this.setState({
        error: 'Please enter an email',
        updating: false,
      });
    } else {
      const RESET_RESPONSE = await fetch(`/api/users/password/reset/${this.state.email}/parent`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': 'Origin',
        }
      });
      const RESET_DATA = await RESET_RESPONSE.json();

      if(RESET_DATA.auth){
        this.setState({
          success: true,
          error: null,
          updating: false,
        });
      } else {
        this.setState({
          error: RESET_DATA.error,
          updating: false,
        });
      }
    }
  }
  render() {
    if(this.state.updating){
      return <Spinner />
    }
    if(this.state.success){
      return (
        <div className = 'mainContainer'>
          <div className = 'verifyContainer'>
            <div className = 'verifySection'>
              <img
                src = {Checkmark}
                className = 'checkmark'
                alt = 'Find and try the best kids activities in Toronto from arts and crafts to STEM for kids to gymnastics for kids'
              />
              <h1 className = 'verifyTitle'>Your password has been reset!</h1>
            </div>
            <div className = 'verifySection'>
              <p className = 'verifyText'>Check your email for a link to reset your password.</p>
            </div>
            <div className = 'verifySection'>
              <NavLink to = '/login' className = 'navButton'>
                <button>Login</button>
              </NavLink>
            </div>
          </div>
        </div>
      );
    }
    return (
      <form onSubmit = {this.resetPassword}>
        <div className = 'formSection'>
          <label className = 'formLabel'>Email</label>
          <input
            onChange = {(e) => this.setState({ email : e.target.value })}
            type = 'email'
            placeholder = 'Email'
            value = {this.state.email}
            className = 'formInput'
          />
        </div>
        <div className = 'formSection'>
          <span className = 'error'>{this.state.error}</span>
          <button>Reset Password</button>
        </div>
      </form>
    );
  }
}