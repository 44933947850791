import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import FormLogin from '@/components/FormLogin';

import './index.css';

class ModalAuth extends Component {
  render() {
    return (
      <div>
        <div className = 'overlay' />
        <div className = 'modalContainer'>
          <div className = 'modalSection'>
            <div className = 'close' onClick = {() => {this.props.handleCloseModal()}}/>
            <h2 className = 'checkoutHeaderText'>Login to your PlayPass account</h2>
            <FormLogin
              handleLogin = {this.props.handleLogin}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ModalAuth);