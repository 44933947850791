import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

import { STORAGE_KEY } from '@/constants'

import Spinner from '@/components/Spinner'

import './index.css';

class FormAddChild extends Component {
  state = {
    firstName: null,
    lastName: null,
    birthday: null,
    gender: null,
    allergies: null,
    children: []
  }
  addChild = async (ev) => {
    ev.preventDefault();
    this.setState({
      error: null,
      addingChild: true
    });
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY);

    if(!this.state.firstName || !this.state.lastName) {
      this.setState({
        error: 'Please enter your child\'s name',
        addingChild: false
      });
    } else if(!this.state.birthday) {
      this.setState({
        error: 'Please enter your child\'s birthday',
        addingChild: false
      });
    } else if(!this.state.gender) {
      this.setState({
        error: 'Please select your child\'s gender',
        addingChild: false
      });
    } else {
      if(DEMO_TOKEN) {
        const CHILD_RESPONSE = await fetch('/api/children/create', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Headers': 'Origin',
            'Authorization': 'Bearer ' + DEMO_TOKEN,
            'x-access-token': DEMO_TOKEN
          },
          body: JSON.stringify({
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            birthday: this.state.birthday,
            gender: this.state.gender,
            allergies: this.state.allergies ? this.state.allergies : 'none'
          })
        });

        const CHILD_DATA = await CHILD_RESPONSE.json();

        if(CHILD_DATA.auth){
          if(this.props.selectChild){
            this.props.selectChild(CHILD_DATA.child._id);
          }
          this.setState({
            firstName: null,
            lastName: null,
            birthday: null,
            gender: null,
            allergies: null
          });
        } else {
          this.setState({
            error: CHILD_DATA.error,
            firstName: null,
            lastName: null,
            birthday: null,
            gender: null,
            allergies: null
          });
        }
        if(this.myFormRef) {
          this.myFormRef.reset();
        }
      } else {
        if(this.props.selectChild) {
          this.props.selectChild({ firstName: this.state.firstName, lastName: this.state.lastName, birthday: this.state.birthday, gender: this.state.gender, allergies: this.state.allergies });
        }
      }
      this.setState({
        addingChild: false
      });
    }
  }
  render() {
    if(this.state.addingChild){
      return <Spinner />
    }
    return (
      <form className = 'formFieldContainer' ref = {(el) => this.myFormRef = el} onSubmit = {this.addChild}>
        <div className = 'formSection'>
          <label className = 'formLabel'>First Name</label>
          <input
            onChange = {(e) => this.setState({ firstName : e.target.value })}
            type = "text"
            placeholder = 'First name'
          />
          <label className = 'formLabel'>Last Name</label>
          <input
            onChange = {(e) => this.setState({ lastName : e.target.value })}
            type = "text"
            placeholder = 'Last name'
          />
          <label className = 'formLabel'>Birthday</label>
          <input
            onChange = {(e) => this.setState({ birthday : moment(e.target.value).unix() })}
            type = "date"
          />
          <label className = 'formLabel'>Gender</label>
          <select
            onChange = {(e) => this.setState({ gender : e.target.value })}>
            <option selected disabled defaultValue> Select Gender </option>
            <option value = 'Male'>Male</option>
            <option value = 'Female'>Female</option>
          </select>
          <label className = 'formLabel'>Allergies</label>
          <input
            onChange = {(e) => this.setState({ allergies : e.target.value })}
            type = "text"
            placeholder = 'Allergies'
          />
        </div>

        <div className = 'formSection'>
          <span className = 'defaultErrorText'>{this.state.error}</span>
        </div>

        <button>
          <span>Add</span>
        </button>
      </form>
    );
  }
}

export default withRouter(FormAddChild);