import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';

import { STORAGE_KEY } from '@/constants';

import './index.css';

export default class LineSession extends Component {
  state = {
    classFull: false,
    bookings: [],
  }
  componentDidMount = async () => {
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY);
    if(DEMO_TOKEN) {
      const BOOKING_RESPONSE = await fetch(`/api/bookings/${this.props.content.session._id}`, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + DEMO_TOKEN,
          'x-access-token': DEMO_TOKEN
        }
      });
      const BOOKING_DATA = await BOOKING_RESPONSE.json();
      if(BOOKING_DATA.auth) {
        let bookings = BOOKING_DATA.bookings
        this.setState({
          bookings: bookings.filter(booking => booking.status === 'ATTEND' && booking.type !== 'locked')
        });
      }
    }
  }
  render() {
    return (
      <div className = 'dropInCardContainer'>
        <span className = 'dropInTimeText'>{moment.unix(this.props.content.session.startDate).format('ddd MMM Do YYYY, h:mm a')} – {moment.unix(this.props.content.session.endDate).format('h:mm a')}</span>
        <div>
          {
            this.state.bookings.length > 0 &&
            <NavLink className = 'lineCancellationText' to = {`/cancel/${this.props.content.session._id}`}>
              Cancel
            </NavLink>
          }
          {
            this.props.content.session.classFull ?
            (
              <span className = 'dropInErrorText'>Class full</span>
            ) :
            (
              <NavLink className = 'dropInNavLink' to = {`/book/${this.props.content.session._id}`}>
                <button>
                  Book
                </button>
              </NavLink>
            )
          }
        </div>
      </div>
    );
  }
}