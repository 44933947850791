import React, { Component } from 'react'
import moment from 'moment'
import { NavLink } from 'react-router-dom'
import LazyLoad from 'react-lazy-load'
import ClampLines from 'react-clamp-lines'

import Spinner from '@/components/Spinner'

import './index.css'

export default class CardSemester extends Component {
  state = {
    loading: true
  }
  componentDidMount = async () => {
    const SEMESTER_RESPONSE = await fetch(`/api/sessions/upcoming/semester/${this.props.semester.activityID}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin'
      }
    });
    const SEMESTER_DATA = await SEMESTER_RESPONSE.json();

    if(SEMESTER_DATA.auth) {
      this.setState({
        semesters: SEMESTER_DATA.sessions.slice(0, 3)
      });
    }
    this.setState({
      loading: false
    });
  }
  render() {
    if(this.state.loading){
      return <Spinner />
    }
    return (
      <div className = 'cardSessionContainer'>
        <div className = 'cardSessionSectionPrimary' to = {`/${this.props.semester.partner.slug}`}>
          <LazyLoad offsetVertical = {800}>
            <img
              className = 'cardSessionImage'
              src = {this.props.semester.activity.activityImage}
              alt = 'PlayPass Partner'
            />
          </LazyLoad>
          <div className = 'cardSectionPadded'>
            <h1 className = 'cardHeader'>{this.props.semester.partner.name}</h1>
            <span className = 'cardSubtitle'>{this.props.semester.activity.address ? this.props.semester.activity.address.neighbourhood : this.props.semester.partner.address.neighbourhood}</span>
          </div>
          <div className = 'cardSectionPadded'>
            <div className = 'cardDescriptionContainer'>
              <span className = 'cardDescriptionPartner'>{this.props.semester.partner.description}</span>
            </div>
          </div>
          <div className = 'cardSectionPaddedMobile'>
            <h1 className = 'cardHeader'>{this.props.semester.activity.name}</h1>
            <span className = 'cardSubtitle'>{this.props.semester.partner.name} · {this.props.semester.activity.ageRange[0]} – {this.props.semester.activity.ageRange[1] === 100 ? '12+' : this.props.semester.activity.ageRange[1]} years old · {this.props.semester.activity.address ? this.props.semester.activity.address.neighbourhood : this.props.semester.partner.address.neighbourhood}</span>
          </div>
          <div className = 'cardSectionPaddedMobile'>
            <div className = 'cardDescriptionContainer'>
              <ClampLines text = {this.props.semester.activity.description} lines = {2} ellipsis = '...' buttons = {false} className = 'cardDescriptionPartnerMobile' />
            </div>
          </div>
          <div className = 'cardSectionPaddedMobile'>
            <div className = 'cardTimeRow'>
              <NavLink to = {`${this.props.semester.partner.slug}/${this.props.semester.activity.slug}`} className = 'cardButtonPrimary'>
                <span className = 'cardButtonText'>View details and book</span>
              </NavLink>
            </div>
          </div>
          {
            this.state.semesters && this.state.semesters.length > 0 ?
            (
              <div className = 'cardSectionPaddedMobile'>
                <h1 className = 'cardSubheader'>Upcoming Semesters</h1>
                <div className = 'cardTimeRow'>
                  {
                    this.state.semesters.map(semester => {
                      return (
                        <NavLink to = {`${semester.partner.slug}/${semester.activity.slug}/${semester.sessionGroupID}`} className = 'cardButton'>
                          <span className = 'cardButtonTextSmall'>{moment.unix(semester.sessionGroup.startDate).format('MMM D')} - {moment.unix(semester.sessionGroup.endDate).format('MMM D')}</span>
                          <span className = 'cardButtonTextSmall'>{semester.sessionGroup.frequency}</span>
                        </NavLink>
                      )
                    })
                  }
                </div>
              </div>
            ) :
            (
              <div />
            )
          }
        </div>
        <div className = 'cardSessionSectionSecondary'>
          <div className = 'cardSectionPadded'>
            <h1 className = 'cardHeader'>{this.props.semester.activity.name}</h1>
            <span className = 'cardSubtitle'>{this.props.semester.partner.name} · {this.props.semester.activity.ageRange[0]} – {this.props.semester.activity.ageRange[1] === 100 ? '12+' : this.props.semester.activity.ageRange[1]} years old · {this.props.semester.activity.address ? this.props.semester.activity.address.neighbourhood : this.props.semester.partner.address.neighbourhood}</span>
          </div>
          <div className = 'cardSectionPadded'>
            <div className = 'cardDescriptionContainer'>
              <span className = 'cardDescriptionPartner'>{this.props.semester.activity.description}</span>
            </div>
          </div>
          <div className = 'cardSectionPadded'>
            <div className = 'cardTimeRow'>
              <NavLink to = {`${this.props.semester.partner.slug}/${this.props.semester.activity.slug}`} className = 'cardButtonPrimary'>
                <span className = 'cardButtonText'>View details and book</span>
              </NavLink>
            </div>
          </div>
          {
            this.state.semesters && this.state.semesters.length > 0 ?
            (
              <div className = 'cardSectionPadded'>
                <h1 className = 'cardSubheader'>Upcoming semesters</h1>
                <div className = 'cardTimeRow'>
                  {
                    this.state.semesters.map(semester => {
                      return (
                        <NavLink to = {`${semester.partner.slug}/${semester.activity.slug}/${semester.sessionGroupID}`} className = 'cardButton'>
                          <span className = 'cardButtonTextSmall'>{moment.unix(semester.sessionGroup.startDate).format('MMM D')} - {moment.unix(semester.sessionGroup.endDate).format('MMM D')}</span>
                          <span className = 'cardButtonTextSmall'>{semester.sessionGroup.frequency}</span>
                        </NavLink>
                      )
                    })
                  }
                </div>
              </div>
            ) :
            (
              <div />
            )
          }
        </div>
      </div>
    );
  }
}