import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import { STORAGE_KEY } from '@/constants';
import { initializeAdTracking } from '@/analytics';

import FormAddChild from '@/components/FormAddChild';
import FormProfile from '@/components/FormProfile';
import Mapper from '@/components/Mapper';
import Spinner from '@/components/Spinner';

import './index.css';

export default class Profile extends Component {
  state = {
    loading: true,
    children: [],
  }
  componentDidMount = async () => {
    initializeAdTracking(this.props.location.pathname);
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY);
    const CHILD_RESPONSE = await fetch(`/api/children/`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      }
    });
    const CHILD_DATA = await CHILD_RESPONSE.json();
    if(CHILD_DATA) {
      this.setState({
        children: CHILD_DATA.children,
        loading: false,
      });
    } else {
      this.setState({
        error: 'There was a problem getting your information. Please try again or contact support!',
        loading: false,
      });
    }
  }
  grabChildren = async () => {
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY);
    const CHILD_RESPONSE = await fetch(`/api/children/`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      }
    });
    const CHILD_DATA = await CHILD_RESPONSE.json();
    this.setState({
      children: CHILD_DATA.children,
    });
  }
  render() {
    if(this.state.loading) {
      return <Spinner />
    }
    return (
      <div className = 'paddedContainer'>
        <div className = 'profileHeader'>
          {
            this.props.global.user &&
            <div className = 'activitiesCreditBox'>
              <span className = 'activitiesCreditText'>You have ${(this.props.global.user.accountBalanceInCents/100).toFixed(2)} left</span>
            </div>
          }
        </div>
        <div className = 'splitContainer'>
          <div className = 'profileLeftContainer'>
            <span className = 'defaultHeaderText'>Profile</span>
            <FormProfile
              user = {this.props.global.user}
              updateUser = {this.props.updateUser}
            />
            <div className = 'navButtonWrapper'>
              <NavLink to = '/profile/change-password' className = 'profileNavContainer'>
                <button className = 'buttonOffWhite'>Change password</button>
              </NavLink>
            </div>
          </div>
        </div>
        <div className = 'splitContainer'>
          <div className = 'profileLeftContainer'>
            <span className = 'defaultHeaderText'>Add Child</span>
            <FormAddChild
              grabChildren = {this.grabChildren}
            />
          </div>
          <div className = 'profileRightContainer'>
            <span className = 'defaultHeaderText'>Children</span>
            <Mapper
              content = {this.state.children}
              type = 'children'
            />
          </div>
        </div>
        <div className = 'navButtonWrapper'>
          <NavLink to = '/logout' className = 'profileLogoutContainer'>
            <button>Logout</button>
          </NavLink>
        </div>
      </div>
    );
  }
}