const POSTS = [
  {
    title: 'Finding Zen in Your Life',
    slug: 'finding-zen-in-your-life',
    image: 'https://i.imgur.com/U9or5XW.jpg',
    content: require('./finding-zen-in-your-life.md')
  },{
    title: 'Are Your Children Acting Out?',
    slug: 'are-your-children-acting-out',
    image: 'https://i.imgur.com/dCDR89V.jpg',
    content: require('./are-your-children-acting-out.md')
  },{
    title: 'Fostering Creativity With Your Kids',
    slug: 'fostering-creativity-with-your-kids',
    image: 'https://i.imgur.com/e8Jtwp7.jpg',
    content: require('./fostering-creativity-with-your-kids.md')
  },{
    title: 'How to Ignite Your Child\'s Passions',
    slug: 'how-to-ignite-your-childs-passions',
    image: 'https://i.imgur.com/BWyFXO2.jpg',
    content: require('./how-to-ignite-your-childs-passion.md')
  }
]

export default async () => {
  return await Promise.all(POSTS.map(async post => {
    const res = await fetch(post.content)
    const content = await res.text()
    return {
      title: post.title,
      slug: post.slug,
      image: post.image,
      content: content,
    }
  }))
}
