import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

import Logo from '@/assets/logos/logo.svg'

import './index.css'

export default class Header extends Component {
  state = {
    hamburgerActive: false
  }
  handleHamburgerClick = () => {
    if(this.state.hamburgerActive){
      this.setState({
        hamburgerActive: false
      });
    } else {
      this.setState({
        hamburgerActive: true
      });
    }
  }
  render() {
    if(this.props.global.user){
      return (
        <div className = 'headerContainer'>
          <div className = 'headerHeaderContainer'>
            {
              this.state.hamburgerActive ?
              (
                <div className = 'hamburger hamburger--slider is-active' type = 'button' onClick = {() => this.handleHamburgerClick()}>
                  <span className = 'hamburger-box'>
                    <span className = 'hamburger-inner'></span>
                  </span>
                </div>
              ) :
              (
                <div className = 'hamburger hamburger--slider' type = 'button' onClick = {() => this.handleHamburgerClick()}>
                  <span className = 'hamburger-box'>
                    <span className = 'hamburger-inner'></span>
                  </span>
                </div>
              )
            }
            <NavLink to = '/' className = 'headerLogoText'>
              <img
                src = {Logo}
                alt = 'PlayPass Best Toronto Kid Activities'
                className = 'headerLogo'
              />
            </NavLink>
          </div>
          <div className = 'headerMenu'>
            <NavLink to = '/activities' className = 'headerHeaderText'>
              <button className = 'headerButton'>Activities</button>
            </NavLink>
            <span className = 'headerHeaderText'>|</span>
            <NavLink to = '/blog' className = 'headerHeaderText'>
              Blog
            </NavLink>
            <NavLink to = '/profile' className = 'headerHeaderText'>
              Profile
            </NavLink>
          </div>
          {
            this.state.hamburgerActive &&
            <div className = 'headerOverlay'>
              <div className = 'hamburgerContainer'>
                <div className = 'hamburgerItem'>
                  <div className = 'hamburgerSection'>
                    <NavLink to = '/activities' className = 'hamburgerNavItem' onClick = {() => this.handleHamburgerClick()}>
                      Activities
                    </NavLink>
                  </div>
                  <div className = 'headerSeparator'/>
                  <div className = 'hamburgerSection'>
                    <NavLink to = '/blog' className = 'hamburgerNavItem' onClick = {() => this.handleHamburgerClick()}>
                      Blog
                    </NavLink>
                    <NavLink to = '/profile' className = 'hamburgerNavItem' onClick = {() => this.handleHamburgerClick()}>
                      Profile
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      );
    } else {
      return (
        <div className = 'headerContainer'>
          <div className = 'headerHeaderContainer'>
            {
              this.state.hamburgerActive ?
              (
                <div className = 'hamburger hamburger--slider is-active' type = 'button' onClick = {() => this.handleHamburgerClick()}>
                  <span className = 'hamburger-box'>
                    <span className = 'hamburger-inner'></span>
                  </span>
                </div>
              ) :
              (
                <div className = 'hamburger hamburger--slider' type = 'button' onClick = {() => this.handleHamburgerClick()}>
                  <span className = 'hamburger-box'>
                    <span className = 'hamburger-inner'></span>
                  </span>
                </div>
              )
            }
            <NavLink to = '/' className = 'headerLogoText'>
              <img
                src = {Logo}
                alt = 'PlayPass Best Toronto Kid Activities'
                className = 'headerLogo'
              />
            </NavLink>
          </div>
          <div className = 'headerMenu'>
            <NavLink to = '/activities' className = 'headerHeaderText'>
              <button>Activities</button>
            </NavLink>
            <NavLink to = '/blog' className = 'headerHeaderText'>
              Blog
            </NavLink>
            <NavLink to = '/login' className = 'headerHeaderText'>
              Login
            </NavLink>
            <NavLink to = '/signup' className = 'headerHeaderText'>
              Sign Up
            </NavLink>
          </div>
          {
            this.state.hamburgerActive &&
            <div className = 'headerOverlay'>
              <div className = 'hamburgerContainer'>
                <div className = 'hamburgerItem'>
                  <div className = 'hamburgerSection'>
                    <NavLink to = '/activities' className = 'hamburgerNavItem' onClick = {() => this.handleHamburgerClick()}>
                      Activities
                    </NavLink>
                    <NavLink to = '/blog' className = 'hamburgerNavItem' onClick = {() => this.handleHamburgerClick()}>
                      Blog
                    </NavLink>
                    <NavLink to = '/login' className = 'hamburgerNavItem' onClick = {() => this.handleHamburgerClick()}>
                      Login
                    </NavLink>
                    <NavLink to = '/signup' className = 'hamburgerNavItem' onClick = {() => this.handleHamburgerClick()}>
                      Sign Up
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      );
    }
  }
}