import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { initializeAdTracking } from '@/analytics';

import ChevronLeft from '@/assets/iconography/global/chevron-left.svg';

import Mapper from '@/components/Mapper';
import Spinner from '@/components/Spinner';
import FilterSemesters from '@/components/FilterSemesters';
import ModalFilterSemesters from '@/components/ModalFilterSemesters';

import './index.css';

class Semesters extends Component {
  state = {
    loading: true,
    day: 'Any day',
    category: 'All categories',
    age: 'All ages',
    discovering: false,
    filterOpen: false
  }
  componentDidMount = async () => {
    initializeAdTracking(this.props.location.pathname);
    let params = await new URLSearchParams(this.props.history.location.search);
    const category = params.get('category')
    const age = params.get('age')
    const day = params.get('day')

    if(day && age && category) {
      this.setState({
        category,
        age,
        day,
      })
      await this.loadSomeSemesters()
    } else {
      await this.loadAllSemesters()
    }
    this.setState({
      loading: false
    })
  }
  loadAllSemesters = async () => {
    const ACTIVITIES_RESPONSE = await fetch('/api/activities/upcoming/semester', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin'
      }
    });
    const ACTIVITIES_DATA = await ACTIVITIES_RESPONSE.json()
    this.setState({
      filter: ACTIVITIES_DATA.activities,
      discovering: false
    })
  }
  loadSomeSemesters = async () => {
    if(this.state.day === 'Any day') {
      await this.loadAllSemesters()
    } else {
      const ACTIVITIES_RESPONSE = await fetch(`/api/activities/upcoming/semester/${this.state.day}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': 'Origin'
        }
      });
      const ACTIVITIES_DATA = await ACTIVITIES_RESPONSE.json()
      let filter
      let content = ACTIVITIES_DATA.activities
      if(this.state.category !== 'All categories' && this.state.age !== 'All ages') {
        filter = content.filter(activity => {
          if(activity.categories.indexOf(this.state.category) >= 0 && ((parseInt(activity.ageRange[0]) <= this.state.age && parseInt(activity.ageRange[1]) >= this.state.age) || (this.state.age === 9 && parseInt(activity.ageRange[1]) >= 9))) {
            return activity;
          } else {
            return null;
          }
        });
      } else if(this.state.category !== 'All categories') {
        filter = content.filter(activity => {
          if(activity.categories.indexOf(this.state.category) >= 0) {
            return activity;
          } else {
            return null;
          }
        });
      } else if(this.state.age !== 'All ages') {
        filter = content.filter(activity => {
          if((parseInt(activity.ageRange[0]) <= this.state.age && parseInt(activity.ageRange[1]) >= this.state.age) || (this.state.age === 9 && parseInt(activity.ageRange[1]) >= 9)) {
            return activity;
          } else {
            return null;
          }
        });
      } else {
        filter = content
      }
      this.setState({
        filter: filter,
        discovering: true
      })
    }
  }
  setAge = (age) => {
    this.setState({
      age: age
    });
  }
  setCategory = (category) => {
    this.setState({
      category: category
    });
  }
  setDay = (day) => {
    this.setState({
      day: day
    });
  }
  toggleFilter = () => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    this.setState({
      filterOpen: this.state.filterOpen ? false : true
    });
  }
  onSearch = async () => {
    await this.props.history.push(`/semesters?day=${this.state.day}&category=${this.state.category}&age=${this.state.age}`);
    await this.loadSomeSemesters();
    await this.toggleFilter();
  }
  onBrowse = async () => {
    await this.props.history.push('/semesters');
    this.setState({
      discovering: false,
      day: 'Any day',
      category: 'All categories',
      age: 'All ages'
    });
    await this.loadAllSemesters();
  }
  render() {
    if(this.state.loading){
      return <Spinner />
    }
    return (
      <div className = 'semestersContainer'>
        {
          this.state.filterOpen ?
          (
            <ModalFilterSemesters
              toggleFilter = {this.toggleFilter}
              age = {this.state.age}
              category = {this.state.category}
              day = {this.state.day}
              onSearch = {this.onSearch}
              setAge = {this.setAge}
              setCategory = {this.setCategory}
              setDay = {this.setDay}
            />
          ) :
          (
            <div />
          )
        }
        <FilterSemesters
          toggleFilter = {this.toggleFilter}
          age = {this.state.age}
          category = {this.state.category}
          day = {this.state.day}
          onSearch = {this.onSearch}
          setAge = {this.setAge}
          setCategory = {this.setCategory}
          setDay = {this.setDay} discovering = {this.state.discovering}
        />
        <div className = 'semestersMainContainer'>
          {
            this.state.discovering ?
            (
              <div className = 'semestersMainSectionDiscover'>
                <div className = 'semestersDiscoverHeader'>
                  <span className = 'semestersDiscoverHeaderTextNav' onClick = {() => this.onBrowse()}>
                    <img
                      src = {ChevronLeft}
                      className = 'basicIcon'
                      alt = 'PlayPass the best way to find kids activities'
                    />
                    All semesters
                  </span>
                  <span className = 'semestersDiscoverHeaderText'>{this.state.filter.length} results</span>
                </div>
                <Mapper
                  content = {this.state.filter}
                  type = 'semester'
                />
              </div>
            ) :
            (
              <div className = 'semestersMainSection'>
                <div className = 'semestersBrowseHeader'>
                  <h1 className = 'semestersBrowseHeaderText'>Browse all semesters</h1>
                </div>
                <Mapper
                  content = {this.state.filter}
                  type = 'activity'
                />
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

export default withRouter(Semesters);