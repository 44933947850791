import React, { Component } from 'react';
import { CardNumberElement, CardExpiryElement, CardCVCElement, injectStripe } from 'react-stripe-elements';

import './index.css';

class FormCheckout extends Component {
  checkout = async (ev) => {
    ev.preventDefault();
    let token
    this.props.disablePaymentButton()
    if(this.props.showCreditCardFields) {
      token = await this.props.stripe.createToken({ type: 'card', name: this.props.name })
    }
    this.props.handleAttemptPayment()
    await this.props.bookClass(token);
  }
  render(){
    return (
      <form className = 'checkoutFormSection' onSubmit = {this.checkout}>
        {
          this.props.showCreditCardFields &&
          <div>
            <div className = 'checkoutHeaderSection'>
              <h2 className = 'checkoutHeaderText'>Payment details</h2>
            </div>
            <label className = 'formSection'>
              Name on Card
              <input
                onChange = {(e) => this.props.updateName(e.target.value)}
                type = 'text'
                placeholder = 'Your name'
                value = {this.props.name}
              />
            </label>
            <label className = 'formSection'>
              Card Details
              <CardNumberElement />
              <div className = 'paymentAdditionalDetailsSection'>
                <div className = 'cardExpiryElement'>
                  <CardExpiryElement />
                </div>
                <div className = 'cardCVCElement'>
                  <CardCVCElement />
                </div>
              </div>
            </label>
          </div>
        }
        <div className = 'checkoutFormSection'>
          <span className = 'defaultErrorText'>{this.props.error}</span>
          {
            this.props.disabledButton ?
            (
              <button disabled>Complete booking!</button>
            ) :
            (
              <button>Complete booking!</button>
            )
          }
        </div>
      </form>
    );
  }
}

export default injectStripe(FormCheckout);