import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

import { initializeAdTracking } from '@/analytics'

import Checkmark from '@/assets/iconography/checkmark.svg'

import Spinner from '@/components/Spinner'

import '@/App.css'

export default class Unsubscribe extends Component {
  state = {
    loading: true,
    unsubscribing: false,
    success: false,
    email: null,
    error: null
  }
  componentDidMount = async () => {
    initializeAdTracking(this.props.location.pathname);
    if(this.props.history.location.search){
      let params = await new URLSearchParams(this.props.history.location.search);
      let token = params.get('token')
      this.setState({
        token
      });
      const VERIFICATION_RESPONSE = await fetch(`/api/users/unsubscribe/${this.state.token}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': 'Origin'
        }
      });
      const VERIFICATION_DATA = await VERIFICATION_RESPONSE.json();
      if(VERIFICATION_DATA.auth) {
        this.setState({
          email: VERIFICATION_DATA.email
        });
      } else {
        this.setState({
          email: null
        });
      }
    }
    this.setState({
      loading: false
    });
  }
  unsubscribe = async (ev) => {
    ev.preventDefault();
    this.setState({
      unsubscribing: true
    })
    if(this.state.email){
      const UNSUBSCRIBE_RESPONSE = await fetch(`/api/users/unsubscribe/${this.state.token}/${this.state.email}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': 'Origin'
        }
      });
      const UNSUBSCRIBE_DATA = await UNSUBSCRIBE_RESPONSE.json();
      if(UNSUBSCRIBE_DATA.auth) {
        this.setState({
          success: UNSUBSCRIBE_DATA.auth,
          unsubscribing: false
        });
      } else {
        this.setState({
          error: UNSUBSCRIBE_DATA.error,
          unsubscribing: false
        });
      }
    } else {
      this.setState({
        error: 'We couldn\'t find your email. Please retry or contact support!',
        unsubscribing: false
      })
    }
  }
  render() {
    if(this.state.loading || this.state.unsubscribing) {
      return <Spinner />
    }
    if(this.state.success) {
      return (
        <div className = 'bookingConfirmationContainer'>
          <div className = 'paymentConfirmationCheckmarkContainer'>
            <img
              className = 'paymentConfirmationCheckmark'
              src = {Checkmark}
              alt = 'Find new children activities'
            />
          </div>
          <div className = 'bookingConfirmationHeaderContainer'>
            <h1>You've unsubscribed successfully!</h1>
            <span className = 'reviewConfirmationText'>We're sad to see you go. If you change your mind you can always resubscribe by entering your email on the home page.</span>
          </div>
          <div className = 'bookingConfirmationShareContainer'>
            <span className = 'reviewConfirmationSubheaderText'>We're sad to see you go. If you change your mind you can always resubscribe by entering your email below</span>
            <NavLink to = '/'>
              <button>Resubscribe</button>
            </NavLink>
          </div>
        </div>
      );
    }
    return (
      <div className = 'paddedContainer'>
        {
          this.state.email ?
          (
            <div className = 'verifyContainer'>
              <div className = 'verifySection'>
                <h1 className = 'verifyTitle'>Are you sure you want to unsubscribe {this.state.email}?</h1>
              </div>
              <form className = 'verifySection' onSubmit = {this.unsubscribe}>
                <button className = 'modalButton'>Yes, unsubscribe</button>
                <NavLink to = '/'>
                  <button className = 'modalButton'>No, go back</button>
                </NavLink>
              </form>
              <div className = 'verifySection'>
                <span className = 'defaultErrorText'>{this.state.error}</span>
              </div>
            </div>
          ) :
          (
            <div className = 'verifyContainer'>
              <div className = 'verifySection'>
                <h1 className = 'verifyTitle'>Sorry, we couldn't find your email.</h1>
              </div>
              <div className = 'verifySection'>
                <h3 className = 'verifyHeader'>Try again or contact support</h3>
                <p className = 'verifyText'>To contact us, please email us at <a href = 'mailto:hello@playpass.ca' className = 'linkText'>hello@playpass.ca</a>.</p>
              </div>
              <div className = 'verifySection'>
                <NavLink to = '/'>
                  <button className = 'modalButton'>Go to home page</button>
                </NavLink>
              </div>
            </div>
          )
        }
      </div>
    );
  }
}
