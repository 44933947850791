import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import ChevronUp from '@/assets/iconography/global/chevron-up.svg'
import ChevronDown from '@/assets/iconography/global/chevron-down.svg'

import 'react-datepicker/dist/react-datepicker.css';
import './index.css';

class ModalFilterActivities extends Component {
  state ={
    toggleAge: false,
    toggleCategory: false,
    toggleDate: false
  }
  toggleAge = () => {
    this.setState({
      toggleAge: this.state.toggleAge ? false : true,
      toggleCategory: false,
      toggleDate: false
    });
    this.toggleDatePicker();
  }
  toggleCategory = () => {
    this.setState({
      toggleCategory: this.state.toggleCategory ? false : true,
      toggleAge: false,
      toggleDate: false
    });
    this.toggleDatePicker();
  }
  toggleDate = () => {
    this.setState({
      toggleDate: this.state.toggleDate ? false : true,
      toggleCategory: false,
      toggleAge: false
    });
    this.toggleDatePicker();
  }
  toggleDatePicker = async () => {
    const { datepicker } = this.refs;
    if(datepicker){
      await datepicker.setOpen(this.state.toggleDate);
    }
  }
  setDate = async (date) => {
    await this.props.setDate(date);
    this.setState({
      toggleDate: false
    });
    await this.toggleDatePicker();
  }
  render() {
    return (
      <div className = 'modalFilterContainer'>
        <div className = 'modalFilterSection'>
          <div className = 'modalMobileHeader'>
            <h1 className = 'mobileHeaderText'>Search</h1>
            <div className = 'modalMobileClose' onClick = {() => this.props.toggleFilter()}/>
          </div>
          <div className = 'filterOptionsSectionMobile'>
            <button className = 'filterButtonContainer' onClick = {() => this.toggleAge()}>
              {
                this.state.toggleAge ?
                (
                  <div className = 'filterOptionSelected'>
                    {this.props.age === 0 || this.props.age === '0' ? 'Under 1' : this.props.age} 
                    <img
                      src = {ChevronUp}
                      className = 'basicIcon'
                      alt = 'PlayPass the best way to find kids activities'
                    />
                  </div>
                ) :
                (
                  <div className = 'filterOptionSelected'>
                    {this.props.age === 0 || this.props.age === '0' ? 'Under 1' : this.props.age} 
                    <img
                      src = {ChevronDown}
                      className = 'basicIcon'
                      alt = 'PlayPass the best way to find kids activities'
                    />
                  </div>
                )
              }
            </button>
            {
              this.state.toggleAge ?
              (
                <div className = 'filterDropdown'>
                  <div
                    className = {this.props.age === 'All ages' ? 'filterDropdownItemSelected' : 'filterDropdownItem'}
                    onClick = {() => {this.props.setAge('All ages'); this.toggleAge()}}
                  >
                    All ages</div>
                  <div
                    className = {this.props.age === 0 ? 'filterDropdownItemSelected' : 'filterDropdownItem'}
                    onClick = {() => {this.props.setAge(0); this.toggleAge()}}
                  >
                    Under 1</div>
                  <div
                    className = {this.props.age === 1 ? 'filterDropdownItemSelected' : 'filterDropdownItem'}
                    onClick = {() => {this.props.setAge(1); this.toggleAge()}}
                  >
                    1
                  </div>
                  <div
                    className = {this.props.age === 2 ? 'filterDropdownItemSelected' : 'filterDropdownItem'}
                    onClick = {() => {this.props.setAge(2); this.toggleAge()}}
                  >
                    2
                  </div>
                  <div
                    className = {this.props.age === 3 ? 'filterDropdownItemSelected' : 'filterDropdownItem'}
                    onClick = {() => {this.props.setAge(3); this.toggleAge()}}
                  >
                    3
                  </div>
                  <div
                    className = {this.props.age === 4 ? 'filterDropdownItemSelected' : 'filterDropdownItem'}
                    onClick = {() => {this.props.setAge(4); this.toggleAge()}}
                  >
                    4
                  </div>
                  <div
                    className = {this.props.age === 5 ? 'filterDropdownItemSelected' : 'filterDropdownItem'}
                    onClick = {() => {this.props.setAge(5); this.toggleAge()}}
                  >
                    5
                  </div>
                  <div
                    className = {this.props.age === 6 ? 'filterDropdownItemSelected' : 'filterDropdownItem'}
                    onClick = {() => {this.props.setAge(6); this.toggleAge()}}
                  >
                    6
                  </div>
                  <div
                    className = {this.props.age === 7 ? 'filterDropdownItemSelected' : 'filterDropdownItem'}
                    onClick = {() => {this.props.setAge(7); this.toggleAge()}}
                  >
                    7
                  </div>
                  <div
                    className = {this.props.age === 8 ? 'filterDropdownItemSelected' : 'filterDropdownItem'}
                    onClick = {() => {this.props.setAge(8); this.toggleAge()}}
                  >
                    8
                  </div>
                  <div
                    className = {this.props.age === 9 ? 'filterDropdownItemSelected' : 'filterDropdownItem'}
                    onClick = {() => {this.props.setAge(9); this.toggleAge()}}
                  >
                    9
                  +</div>
                </div>
              ) :
              (
                <div />
              )
            }
          </div>
          <div className = 'filterOptionsSectionMobile'>
            <button className = 'filterButtonContainer' onClick = {() => this.toggleCategory()}>
              {
                this.state.toggleCategory ?
                (
                  <div className = 'filterOptionSelected'>{this.props.category} <img src = {ChevronUp} className = 'basicIcon' alt = 'PlayPass the best way to find kids activities' /></div>
                ) :
                (
                  <div className = 'filterOptionSelected'>{this.props.category} <img src = {ChevronDown} className = 'basicIcon' alt = 'PlayPass the best way to find kids activities' /></div>
                )
              }
            </button>
            {
              this.state.toggleCategory ?
              (
                <div className = 'filterDropdown'>
                  <div
                    className = {this.props.category === 'All categories' ? 'filterDropdownItemSelected' : 'filterDropdownItem'}
                    onClick = {() => {this.props.setCategory('All categories'); this.toggleCategory()}}
                  >
                    All categories
                  </div>
                  <div
                    className = {this.props.category === 'Arts & Crafts' ? 'filterDropdownItemSelected' : 'filterDropdownItem'}
                    onClick = {() => {this.props.setCategory('Arts & Crafts'); this.toggleCategory()}}
                  >
                    Arts & Crafts
                  </div>
                  <div
                    className = {this.props.category === 'Baby & Toddler' ? 'filterDropdownItemSelected' : 'filterDropdownItem'}
                    onClick = {() => {this.props.setCategory('Baby & Toddler'); this.toggleCategory()}}
                  >
                    Baby & Toddler
                  </div>
                  <div
                    className = {this.props.category === 'Creative' ? 'filterDropdownItemSelected' : 'filterDropdownItem'}
                    onClick = {() => {this.props.setCategory('Creative'); this.toggleCategory()}}
                  >
                    Creative
                  </div>
                  <div
                    className = {this.props.category === 'Learning & Technology' ? 'filterDropdownItemSelected' : 'filterDropdownItem'}
                    onClick = {() => {this.props.setCategory('Learning & Technology'); this.toggleCategory()}}
                  >
                    Learning & Technology
                  </div>
                  <div
                    className = {this.props.category === 'Music' ? 'filterDropdownItemSelected' : 'filterDropdownItem'}
                    onClick = {() => {this.props.setCategory('Music'); this.toggleCategory()}}
                  >
                    Music
                  </div>
                  <div
                    className = {this.props.category === 'Play' ? 'filterDropdownItemSelected' : 'filterDropdownItem'}
                    onClick = {() => {this.props.setCategory('Play'); this.toggleCategory()}}
                  >
                    Play
                  </div>
                  <div
                    className = {this.props.category === 'Sports & Fitness' ? 'filterDropdownItemSelected' : 'filterDropdownItem'}
                    onClick = {() => {this.props.setCategory('Sports & Fitness'); this.toggleCategory()}}
                  >
                    Sports & Fitness
                  </div>
                </div>
              ) :
              (
                <div />
              )
            }
          </div>
          <div className = 'filterOptionsSectionMobile'>
            <button className = 'filterButtonContainer' onClick = {() => this.toggleDate()}>
              {
                this.state.toggleDate ?
                (
                  <div className = 'filterOptionSelected'>
                    {this.props.date === 'Any date' ? 'Any date' : moment(this.props.date).format('MMM. D, YYYY')} 
                    <img
                      src = {ChevronUp}
                      className = 'basicIcon'
                      alt = 'PlayPass the best way to find kids activities'
                    />
                  </div>
                ) :
                (
                  <div className = 'filterOptionSelected'>
                    {this.props.date === 'Any date' ? 'Any date' : moment(this.props.date).format('MMM. D, YYYY')} 
                    <img
                      src = {ChevronDown}
                      className = 'basicIcon'
                      alt = 'PlayPass the best way to find kids activities'
                    />
                  </div>
                )
              }
            </button>
            {
              this.state.toggleDate ?
              (
                <div className = 'filterDatePickerDropdown'>
                  <div className = {this.props.date === 'Any date' ? 'filterDropdownItemSelected' : 'filterDropdownItem'} onClick = {() => this.setDate('Any date')}>Any date</div>
                  <DatePicker
                    ref = 'datepicker'
                    selected = {this.props.date === 'Any date' ? moment().toDate() : this.props.date}
                    onChange = {date => {this.setDate(date); this.toggleDate();}}
                    dateFormat = 'MMM. d, yyyy'
                    minDate = {moment().toDate()}
                    inline
                  />
                </div>
              ) :
              (
                <div />
              )
            }
          </div>
          <div className = 'filterOptionsButtonSectionMobile' onClick = {() => this.props.onSearch()}>
            <span className = 'filterOptionsButtonMobileText'>Search</span>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ModalFilterActivities);