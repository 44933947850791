import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import marked from 'marked'

import { initializeAdTracking } from '@/analytics'

import Spinner from '@/components/Spinner'

import getPosts from '@/posts'

import './index.css';

export default class Post extends Component {
  state = {
    loading: true,
    post: null
  }
  componentDidMount = async () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    initializeAdTracking(this.props.location.pathname);
    const slug = this.props.match.params.slug

    const posts = await getPosts()

    const post = posts.find((post) => {
      return post.slug === slug
    })

    this.setState({
      post,
      loading: false,
    })
  }
  createMarkup = (content) => {
    const html = marked(content)
    return { __html: html };
  }
  render() {
    if (this.state.loading) {
      return <Spinner />
    } else if (this.state.post != null) {
      return (
        <div className = 'blogContainer'>
          <div className = 'post'>
            <h2 className = 'postTitle'>{this.state.post.title}</h2>
            <img className = 'postImage' src = {this.state.post.image} alt = 'PlayPass, find the best kids activities in your area all over Toronto'/>
            <div className = 'postSection' dangerouslySetInnerHTML = {this.createMarkup(this.state.post.content)} />
            <div className = 'postTextContainer'>
              <p className = 'postText'>Do you struggle with finding the best kids activities? <NavLink smooth to = '/' className = 'linkText'>Subscribe to our newsletter</NavLink> to get weekly activities in your area.</p>
            </div>
            <div className = 'blogCTAContainer'>
              <h1 className = 'blogCTAHeader'>Find the perfect kids activity</h1>
              <h2 className = 'blogCTASubtitle'>Book drop-in activities for your child in your area.</h2>
              <div>
                <NavLink smooth to = '/activities'>
                  <button className = 'blogCTA'>Browse classes!</button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className = 'blogContainer'>
          <div className = 'post'>
            <div className = 'noPost'>
              <h1 className = 'noPostText'>There doesn't seem to be a post here! <NavLink to = '/blog' className = 'linkText'>Go to blog</NavLink></h1>
            </div>
            <div className = 'blogCTAContainer'>
              <h1 className = 'blogCTAHeader'>Find the perfect kids activity</h1>
              <h2 className = 'blogCTASubtitle'>Book drop-in activities for your child in your area.</h2>
              <div>
                <NavLink smooth to = '/activities'>
                  <button className = 'blogCTA'>Browse classes!</button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}