import React, { Component } from 'react';

import { initializeAdTracking } from '@/analytics';

import './index.css';

export default class PrivacyPolicy extends Component {
  componentDidMount = () => {
    initializeAdTracking(this.props.location.pathname);
  }
  render() {
    return (
      <div className = 'legalContainer'>
        <h1 className = 'legalHeaderText'>Privacy Policy</h1>
        <h3 className = 'legalSubtitleText'>Last Updated: July 24 2019</h3>

        <p className = 'legalText'>This Privacy Policy describes how your personal information is collected, used, and shared when you visit, use, or make a purchase from PlayPass Technologies Inc. (“PlayPass”).</p>

        <h2 className = 'legalSubheaderText'>PERSONAL INFORMATION WE COLLECT</h2>
        <p className = 'legalText'>When you visit PlayPass, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse PlayPass, we collect information about the individual web pages or products that you view, what websites or search terms referred you to PlayPass, and information about how you interact with PlayPass. We refer to this automatically-collected information as “Device Information.”</p>
        <p className = 'legalText'>We collect Device Information using the following technologies:</p>
        <ul>
          <li className = 'legalText'>“Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit <a href = "http://www.allaboutcookies.org">http://www.allaboutcookies.org</a>.</li>
          <li className = 'legalText'>“Log files” track actions occurring on PlayPass, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.</li>
          <li className = 'legalText'>“Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse PlayPass.</li>
        </ul>
        <p className = 'legalText'>Additionally when you make a purchase or attempt to make a purchase through PlayPass, we collect certain information from you, including your name, billing address, shipping address, payment information (including credit card numbers), email address, and phone number.  We refer to this information as “Order Information.”</p>
        <p className = 'legalText'>When creating an Account, Personal Information will be provided including a password, name, location, and email address. Personal Information from the creation of the account may be made public to others and us in order to identify your Account. Other Personal Information may be used to communicate with you both for administrative and promotional purposes. You may unsubscribe promotional messages through your e-mail settings.</p>
        <p className = 'legalText'>We do not collect financial information including credit card numbers, type expiration dates, CVS numbers. All payment information is collected and stored by our third party payment processing company - Stripe Inc. Please refer to their privacy policy for the use and storage of your information in relation to payments made.</p>
        <p className = 'legalText'>When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and Order Information.</p>

        <h2 className = 'legalSubheaderText'>HOW DO WE USE YOUR PERSONAL INFORMATION?</h2>
        <p className = 'legalText'>We use the Order Information that we collect generally to fulfill any orders placed through PlayPass (including processing your payment information, arranging for shipping, and providing you with invoices and/or order confirmations).  Additionally, we use this Order Information to:</p>
        <ul>
          <li className = 'legalText'>Communicate with you;</li>
          <li className = 'legalText'>Screen our orders for potential risk or fraud; and</li>
          <li className = 'legalText'>When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.</li>
        </ul>

        <h2 className = 'legalSubheaderText'>SHARING YOUR PERSONAL INFORMATION</h2>
        <p className = 'legalText'>We share your Personal Information with third parties to help us use your Personal Information, as described above.  We also use Google Analytics to help us understand how our customers use PlayPass--you can read more about how Google uses your Personal Information here: <a href = "https://www.google.com/intl/en/policies/privacy/">https://www.google.com/intl/en/policies/privacy/</a>.  You can also opt-out of Google Analytics here: <a href = "https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.</p>
        <p className = 'legalText'>Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, a search warrant or other lawful request for information we receive, or to otherwise protect our rights.</p>

        <h2 className = 'legalSubheaderText'>DO NOT TRACK</h2>
        <p className = 'legalText'>Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.</p>

        <h2 className = 'legalSubheaderText'>DATA RETENTION</h2>
        <p className = 'legalText'>When you place an order through PlayPass, we will maintain your Order Information for our records unless and until you ask us to delete this information.</p>

        <h2 className = 'legalSubheaderText'>CHANGES</h2>
        <p className = 'legalText'>We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.</p>

        <h2 className = 'legalSubheaderText'>CONTACT US</h2>
        <p className = 'legalText'>For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at <a href = 'mailto:hello@playpass.ca?subject=Privacy Concerns'>hello@playpass.ca</a>.</p>
      </div>
    );
  }
}