import React, { Component } from 'react';
import LazyLoad from 'react-lazy-load';

import { initializeAdTracking } from '@/analytics';

import Spinner from '@/components/Spinner';
import Mapper from '@/components/Mapper';
import MapView from '@/components/MapView';

import './index.css';

export default class Partners extends Component {
  state = {
    loading: true,
  }
  componentDidMount = async () => {
    initializeAdTracking(this.props.location.pathname);
    const PARTNER_RESPONSE = await fetch('/api/partners', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin'
      }
    });
    const PARTNER_DATA = await PARTNER_RESPONSE.json();

    if(PARTNER_DATA.auth && PARTNER_DATA.partners.length > 0){
      this.setState({
        partners: PARTNER_DATA.partners,
      });
    }
    this.setState({
      loading: false
    });
  }
  render() {
    if(this.state.loading){
      return <Spinner />
    }
    return (
      <div className = 'partnerPageContainer'>
        <div className = 'partnerCardContainer'>
          <Mapper
            content = {this.state.partners}
            type = 'provider'
          />
        </div>
        <LazyLoad offsetVertical = {600} className = 'partnerMapContainer'>
          <div className = 'partnerMapView'>
            <MapView
              content = {this.state.partners}
              type = 'providers'
            />
          </div>
        </LazyLoad>
      </div>
    );
  }
}