import React, { Component } from 'react';

import { initializeAdTracking } from '@/analytics';

import './index.css';

export default class PlayPassGuarantee extends Component {
  componentDidMount = () => {
    initializeAdTracking(this.props.location.pathname);
  }
  render() {
    return (
      <div className = 'legalContainer'>
        <h1 className = 'legalHeaderText'>The PlayPass Guarantee</h1>
        <h3 className = 'legalSubtitleText'>Last Updated: November 24 2019</h3>

        <h2 className = 'legalSubheaderText'>PLAYPASS GUARANTEE EXPLAINED</h2>
        <p className = 'legalText'>We work with all our providers to guarantee the lowest price for your activity when you book directly with PlayPass.</p>
        <p className = 'legalText'>In the unlikely event that you find the same activity on another website / app at a lower price, please contact our support team. We will match the price and refund the difference, if the following conditions are met:</p>
        <ol>
          <li className = 'legalText'>You contact us within 48 hours of booking, providing a link to the exact offer and quoted price including all booking fees.</li>
          <li className = 'legalText'>The activity is bookable online for everyone and available when we check.</li>
          <li className = 'legalText'>The alternative offer is identical to the booking you have made. This means it must be:</li>
          <ul>
            <li className = 'legalText'>The exact same activity</li>
            <li className = 'legalText'>Occurring at the same date / time</li>
            <li className = 'legalText'>At the same location</li>
            <li className = 'legalText'>For the same number of attendees</li>
            <li className = 'legalText'>Operated by the same provider</li>
            <li className = 'legalText'>Exclusive of membership discounts</li>
          </ul>
        </ol>
        <p className = 'legalText'>If we are unable to match the price of the alternative offer because you are unable to meet these conditions, then you can retain your original booking, or make use of the provider’s refund policy.</p>
        <p className = 'legalText'>The maximum claim per person under this guarantee is capped at $100.</p>

        <h2 className = 'legalSubheaderText'>CONTACT INFORMATION</h2>
        <p className = 'legalText'>Questions about The PlayPass Guarantee should be sent to us at <a href = 'mailto:hello@playpass.ca?subject=PlayPass Guarantee Concerns'>hello@playpass.ca</a>.</p>
      </div>
    );
  }
}