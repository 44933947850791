import React, { Component } from 'react';

import { initializeAdTracking } from '@/analytics';

import Spinner from '@/components/Spinner';
import Mapper from '@/components/Mapper';
import MapView from '@/components/MapView';

import NotFound from '@/pages/NotFound';

import './index.css';

export default class Provider extends Component {
  state = {
    loading: true,
  }
  componentDidMount = async () => {
    initializeAdTracking(this.props.location.pathname);
    let partner, address, activities = []
    let partnerSlug = this.props.match.params.partnerSlug
    const PARTNER_RESPONSE = await fetch(`/api/partners/${partnerSlug}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin'
      }
    });
    const ACTIVITIES_RESPONSE = await fetch(`/api/activities/${partnerSlug}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin'
      }
    });
    const PARTNER_DATA = await PARTNER_RESPONSE.json();
    const ACTIVITIES_DATA = await ACTIVITIES_RESPONSE.json();

    if(PARTNER_DATA.auth) {
      partner = PARTNER_DATA.partner
      address = PARTNER_DATA.partner.address ? ((PARTNER_DATA.partner.address.addressLine2 ? PARTNER_DATA.partner.address.addressLine2 + ' - ' : '') + PARTNER_DATA.partner.address.addressLine1 + ', ' + PARTNER_DATA.partner.address.city + ', ' + PARTNER_DATA.partner.address.postalCode) : null
    }
    if(ACTIVITIES_DATA.auth) {
      activities = ACTIVITIES_DATA.activities
    }
    await this.setState({
      partner,
      activities,
      address,
      loading: false
    })
  }
  render() {
    if(this.state.loading) {
      return <Spinner />
    }
    if(!this.state.partner) {
      return <NotFound />
    }
    return (
      <div className = 'providerContainer'>
        <MapView
          content = {this.state.address}
          type = 'provider'
        />
        <div className = 'providerSection'>
          <div className = 'providerContainerRow'>
            <img
              src = {this.state.partner.logo}
              alt = {this.state.partner.name}
              className = 'pagePartnerImage'
            />
            <div className = 'providerDefaultContainer'>
              <span className = 'providerSubheaderText'>{this.state.partner.name}</span>
              <span className = 'providerText'>{this.state.address}</span>
            </div>
          </div>
        </div>

        <div className = 'providerBox'>
          <span className = 'providerSubheaderText'>Upcoming Activities</span>
          <Mapper
            content = {this.state.activities}
            type = 'activity'
          />
        </div>

        <div className = 'providerBox'>
          <span className = 'providerSubheaderText'>About {this.state.partner.name}</span>
          <span className = 'providerDescriptiveText'>{this.state.partner.description}</span>
        </div>
      </div>
    );
  }
}