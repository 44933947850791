import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import './index.css';

export default class SecondCTA extends Component {
  render() {
    return (
      <div className = 'secondCTAContainer'>
        <div className = 'secondCTASection'>
          <div className = 'secondCTAPrimary'>
            <h1 className = 'secondCTAHeader'>Always pay the lowest price</h1>
            <h2 className = 'secondCTASubheader'>We guarantee you always pay the lowest price through PlayPass</h2>
            <NavLink to = '/activities'>
              <button className = 'secondCTAButton'>Find new activities</button>
            </NavLink>
          </div>
          <div className = 'secondCTASecondary'>
            <div className = 'secondCTABackground' />
          </div>
        </div>
      </div>
    );
  }
}