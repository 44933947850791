import React, { Component } from 'react';
import moment from 'moment';

import Female from '@/assets/iconography/female.svg';
import Male from '@/assets/iconography/male.svg';

import Spinner from '@/components/Spinner';

import './index.css';

export default class CardChild extends Component {
  state = {
    loading: true,
    error: null
  }
  componentDidMount = async () => {
    if(moment().diff(moment.unix(this.props.child.birthday), 'years') === 0) {
      this.setState({
        age: moment().diff(moment.unix(this.props.child.birthday), 'months') + ' months old',
        loading: false
      });
    } else {
      this.setState({
        age: moment().diff(moment.unix(this.props.child.birthday), 'years') + ' years old',
        loading: false
      });
    }
  }
  render() {
    if(this.state.loading) {
      return (
        <Spinner />
      );
    }
    if(this.props.child.gender === 'Female') {
      return (
        <div className = 'defaultCardContainer'>
          <img
            src = {Female}
            alt = 'Female gender icon PlayPass'
            className = 'childGenderIcon'
          />
          <span className = 'childInfoText'>{this.props.child.firstName} {this.props.child.lastName}, {this.state.age}</span>
        </div>
      );
    }
    return (
      <div className = 'defaultCardContainer'>
        <img
          src = {Male}
          alt = 'Male gender icon PlayPass'
          className = 'childGenderIcon'
        />
        <span className = 'childInfoText'>{this.props.child.firstName} {this.props.child.lastName}, {this.state.age}</span>
      </div>
    );
  }
}