import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { STORAGE_KEY } from '@/constants';

import './index.css';

class FormChangePassword extends Component {
  state = {
    currentPassword: null,
    newPassword: null,
    confirmPassword: null,
    updating: false
  }
  handleChangePassword = async (ev) => {
    ev.preventDefault();
    this.setState({
      error: null,
      success: false,
      updating: true
    });
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY);
    if(!this.state.currentPassword || !this.state.newPassword || !this.state.confirmPassword) {
      this.setState({
        error: 'Please enter all password fields',
        success: false,
        updating: false
      });
    } else if(this.state.newPassword !== this.state.confirmPassword) {
      this.setState({
        error: 'New passwords don\'t match!',
        success: false,
        updating: false
      });
    } else if(this.state.newPassword.length < 6) {
      this.setState({
        error: 'Your new password must be at least 6 characters long',
        success: false,
        updating: false
      })
    } else {
      const UPDATE_RESPONSE = await fetch('/api/auth/update/password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': 'Origin',
          'Authorization': 'Bearer ' + DEMO_TOKEN,
          'x-access-token': DEMO_TOKEN
        },
        body: JSON.stringify({
          currentPassword: this.state.currentPassword,
          newPassword: this.state.newPassword,
          confirmPassword: this.state.confirmPassword
        })
      });
      const UPDATE_DATA = await UPDATE_RESPONSE.json();

      if(UPDATE_DATA.auth) {
        this.setState({
          success: true,
          error: null,
          updating: false
        });
      } else {
        this.setState({
          error: UPDATE_DATA.error,
          success: false,
          updating: false
        });
      }
      await this.props.updateUser();

      setTimeout(() => {
        this.setState({
          updating: false,
          success: false
        });
      }, 1500);
    }
  }
  render() {
    return (
      <form className = 'formFieldContainer' onSubmit = {this.handleChangePassword}>
        <div className = 'formSection'>
          <label className = 'formLabelLeftAlign'>Current password</label>
          <input
            onChange = {(e) => this.setState({ currentPassword : e.target.value })}
            type = 'password'
            placeholder = 'Current password'
            value = {this.state.currentPassword}
          />
          <label className = 'formLabelLeftAlign'>New password</label>
          <input
            onChange = {(e) => this.setState({ newPassword : e.target.value })}
            type = 'password'
            placeholder = 'New password (at least 6 characters)'
            value = {this.state.newPassword}
          />
          <label className = 'formLabelLeftAlign'>Confirm new password</label>
          <input
            onChange = {(e) => this.setState({ confirmPassword : e.target.value })}
            type = 'password'
            placeholder = 'Confirm password (at least 6 characters)'
            value = {this.state.confirmPassword}
          />
        </div>

        {
          this.state.success ?
          (
            <div className = 'formSection'>
              <span className = 'formSuccessText'>Your password was successfully updated.</span>
              <button className = 'formSuccessProfileButton' disabled>Success!</button>
            </div>
          ) : this.state.updating ?
          (
            <div className = 'formSection'>
              <button disabled>Updating...</button>
            </div>
          ) :
          (
            <div className = 'formSection'>
              <span className = 'defaultErrorText'>{this.state.error}</span>
              <button>Update</button>
            </div>
          )
        }
      </form>
    );
  }
}

export default withRouter(FormChangePassword);