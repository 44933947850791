import React, { Component } from 'react';

import { initializeAdTracking } from '@/analytics';

import FormResetPassword from '@/components/FormResetPassword';

import './index.css';

export default class ResetPassword extends Component {
  componentDidMount = () => {
    initializeAdTracking(this.props.location.pathname);
  }
  render() {
    return (
      <div className = 'pageContainer'>
        <span className = 'formHeader'>Reset Password</span>
        <FormResetPassword />
      </div>
    );
  }
}