import React, { Component } from 'react';

import FormEmailSignup from '@/components/FormEmailSignup';

import './index.css';

export default class SplashFinalCTA extends Component {
  render() {
    return (
      <div className = 'CTAEmailFocusSection'>
        <div className = 'CTAEmailFocusContainer'>
          <h1 className = 'CTAHeader'>Join our community of parents</h1>
          <span className = 'CTASubheader'>Connect with other loving parents and start exploring today</span>
          <FormEmailSignup />
        </div>
      </div>
    );
  }
}