import React, { Component } from 'react';

import FormEmailSignup from '@/components/FormEmailSignup';

import './index.css';

export default class SplashFirstCTA extends Component {
  render() {
    return (
      <div className = 'CTAContainer'>
        <div className = 'CTASection'>
          <div className = 'CTAMain'>
            <h1 className = 'CTAHeader'>Activities just for you</h1>
            <span className = 'CTASubheader'>We send curated activities to your inbox every week to save you time</span>
            <FormEmailSignup />
          </div>
          <div className = 'CTASecondary'>
            <div className = 'CTAEmailBackground' />
          </div>
        </div>
      </div>
    );
  }
}