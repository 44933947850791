import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import WhiteCheckmark from '@/assets/iconography/whiteCheckmark.svg';

import './index.css';

class FormEmailSignup extends Component {
  state = {
    error: null,
    email: null,
    attemptingSignup: false
  }
  signup = async (ev) => {
    ev.preventDefault();
    this.setState({
      attemptingSignup: true,
      error: null
    });
    if(!this.state.email) {
      this.setState({
        error: 'Please enter an email address',
        attemptingSignup: false
      });
    } else {
      const EMAIL_RESPONSE = await fetch('/api/users/create/parent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': 'Origin'
        },
        body: JSON.stringify({
          user: {
            email: this.state.email
          },
          source: 'email',
          ref: null
        })
      });

      const EMAIL_DATA = await EMAIL_RESPONSE.json();
      if(EMAIL_DATA.auth) {
        this.setState({
          attemptingSignup: false,
          signupSuccessful: true
        });
        setTimeout(() => {
          this.setState({
            signupSuccessful: false
          });
        }, 1500);
      } else if(EMAIL_DATA.error === 'Member Exists') {
        this.setState({
          error: 'That email is already signed up!',
          attemptingSignup: false
        });
      } else if(EMAIL_DATA.error === 'Invalid Resource') {
        this.setState({
          error: 'Oops! Something went wrong, please try again!',
          attemptingSignup: false
        });
      } else {
        this.setState({
          error: EMAIL_DATA.error,
          attemptingSignup: false
        });
      }
    }
  }
  render() {
    return (
      <form className = 'formFieldContainer' onSubmit = {this.signup}>
        {
          this.state.signupSuccessful ?
          (
            <div className = 'formNewsletterSection'>
              <div className = 'formNewsletterContainer'>
                <input
                  onChange = {(e) => this.setState({ email : e.target.value })}
                  type = "email"
                  placeholder = 'Email address'
                  value = {this.state.email}
                  disabled
                />
                <button className = 'formSuccessNewsletterButton' disabled>
                  <img
                    className = 'formSuccessCheckmark'
                    src = {WhiteCheckmark}
                    alt = 'PlayPass find new kid activities in Toronto'
                  />
                  <span className = 'formSuccessText'>Success!</span>
                </button>
              </div>
              <span className = 'defaultErrorText'>{this.state.error}</span>
            </div>
          ) : !this.state.signupSuccessful && this.state.attemptingSignup ?
          (
            <div className = 'formNewsletterSection'>
              <div className = 'formNewsletterContainer'>
                <input
                  onChange = {(e) => this.setState({ email : e.target.value })}
                  type = "email"
                  placeholder = 'Email address'
                  value = {this.state.email}
                  disabled
                />
                <button className = 'formDisabledNewsletterButton' disabled>
                  Signing up...
                </button>
              </div>
              <span className = 'defaultErrorText'>{this.state.error}</span>
            </div>
          ) :
          (
            <div className = 'formNewsletterSection'>
              <div className = 'formNewsletterContainer'>
                <input
                  onChange = {(e) => this.setState({ email : e.target.value })}
                  type = "email"
                  placeholder = 'Email address'
                  value = {this.state.email}
                />
                <button className = 'formNewsletterButton'>
                  Subscribe!
                </button>
              </div>
              <span className = 'defaultErrorText'>{this.state.error}</span>
            </div>
          )
        }
      </form>
    );
  }
}

export default withRouter(FormEmailSignup);