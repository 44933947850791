import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import { initializeAdTracking } from '@/analytics';

import ChevronLeft from '@/assets/iconography/global/chevron-left.svg';

import Mapper from '@/components/Mapper';
import Spinner from '@/components/Spinner';
import FilterActivities from '@/components/FilterActivities';
import ModalFilterActivities from '@/components/ModalFilterActivities';

import './index.css';

class Activities extends Component {
  state = {
    loading: true,
    date: 'Any date',
    category: 'All categories',
    age: 'All ages',
    discovering: false,
    filterOpen: false,
  }
  componentDidMount = async () => {
    initializeAdTracking(this.props.location.pathname);
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    let params = await new URLSearchParams(this.props.history.location.search);
    const date = params.get('date')
    const category = params.get('category')
    const age = params.get('age')

    if(date && category && age) {
      this.setState({
        category,
        age,
        date: date === 'Any date' ? 'Any date' : new Date(moment(date)),
      });
      await this.loadSomeActivities(date)
    } else {
      await this.loadAllActivities();
    }
    this.setState({
      loading: false
    });
  }
  loadSomeActivities = async (date) => {
    let filter, content;
    if(date === 'Any date') {
      const ACTIVITIES_RESPONSE = await fetch('/api/activities/upcoming/session', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': 'Origin'
        }
      });
      const ACTIVITIES_DATA = await ACTIVITIES_RESPONSE.json();
      content = ACTIVITIES_DATA.activities
    } else {
      const SESSIONSBYDATE_RESPONSE = await fetch(`/api/sessions/date/${moment(this.state.date).unix()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': 'Origin'
        }
      });
      const SESSIONSBYDATE_DATA = await SESSIONSBYDATE_RESPONSE.json();
      content = SESSIONSBYDATE_DATA.sessions
    }
    if(this.state.category !== 'All categories' && this.state.age !== 'All ages') {
      filter = content.filter(item => {
        const activity = item.activity
        if(activity.categories.indexOf(this.state.category) >= 0 && ((parseInt(activity.ageRange[0]) <= this.state.age && parseInt(activity.ageRange[1]) >= this.state.age) || (this.state.age === 9 && parseInt(activity.ageRange[1]) >= 9))){
          return content;
        } else {
          return null;
        }
      });
    } else if(this.state.category !== 'All categories') {
      filter = content.filter(item => {
        const activity = item.activity
        if(activity.categories.indexOf(this.state.category) >= 0){
          return content;
        } else {
          return null;
        }
      });
    } else if(this.state.age !== 'All ages') {
      filter = content.filter(item => {
        const activity = item.activity
        if((parseInt(activity.ageRange[0]) <= this.state.age && parseInt(activity.ageRange[1]) >= this.state.age) || (this.state.age === 9 && parseInt(activity.ageRange[1]) >= 9)){
          return content;
        } else {
          return null;
        }
      });
    } else {
      filter = content;
    }
    this.setState({
      filter: filter,
      discovering: true,
    });
  }
  loadAllActivities = async () => {
    const ACTIVITIES_RESPONSE = await fetch('/api/activities/upcoming/session', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin'
      }
    });
    const ACTIVITIES_DATA = await ACTIVITIES_RESPONSE.json();
    this.setState({
      filter: ACTIVITIES_DATA.activities,
      discovering: false,
    });
  }
  setAge = (age) => {
    this.setState({
      age: age
    });
  }
  setCategory = (category) => {
    this.setState({
      category: category
    });
  }
  setDate = (date) => {
    this.setState({
      date: date
    });
  }
  toggleFilter = () => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    this.setState({
      filterOpen: this.state.filterOpen ? false : true
    });
  }
  onSearch = async () => {
    if(this.state.date === 'Any date') {
      this.props.history.push('/activities?date=' + this.state.date + '&category=' + this.state.category + '&age=' + this.state.age);
    } else {
      this.props.history.push('/activities?date=' + moment(this.state.date).format('YYYY-MM-DD') + '&category=' + this.state.category + '&age=' + this.state.age);
    }
    await this.loadSomeActivities(this.state.date)
    await this.toggleFilter();
  }
  onBrowse = async () => {
    this.props.history.push('/activities');
    this.setState({
      date: 'Any date',
      category: 'All categories',
      age: 'All ages',
    })
    await this.loadAllActivities();
  }
  render() {
    if(this.state.loading){
      return <Spinner />
    }
    return (
      <div className = 'activitiesContainer'>
        {
          this.state.filterOpen ?
          (
            <ModalFilterActivities
              toggleFilter = {this.toggleFilter}
              age = {this.state.age}
              category = {this.state.category}
              date = {this.state.date}
              onSearch = {this.onSearch}
              setAge = {this.setAge}
              setCategory = {this.setCategory}
              setDate = {this.setDate}
            />
          ) :
          (
            <div />
          )
        }
        <FilterActivities
          toggleFilter = {this.toggleFilter}
          age = {this.state.age}
          category = {this.state.category}
          date = {this.state.date}
          onSearch = {this.onSearch}
          setAge = {this.setAge}
          setCategory = {this.setCategory}
          setDate = {this.setDate}
          discovering = {this.state.discovering}
        />
        <div className = 'activitiesMainContainer'>
          {
            this.state.discovering ?
            (
              <div className = 'activitiesMainSectionDiscover'>
                <div className = 'activitiesDiscoverHeader'>
                  <span className = 'activitiesDiscoverHeaderTextNav' onClick = {() => this.onBrowse()}>
                    <img
                      src = {ChevronLeft}
                      className = 'basicIcon'
                      alt = 'PlayPass the best way to find kids activities'
                    />
                    All activities
                  </span>
                  <span className = 'activitiesDiscoverHeaderText'>{this.state.filter.length} results</span>
                </div>
                <Mapper
                  content = {this.state.filter}
                  type = 'session'
                />
              </div>
            ) :
            (
              <div className = 'activitiesMainSection'>
                <div className = 'activitiesBrowseHeader'>
                  <h1 className = 'activitiesBrowseHeaderText'>Browse all activities</h1>
                </div>
                <Mapper
                  content = {this.state.filter}
                  type = 'activity'
                />
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

export default withRouter(Activities);