import React, { Component } from 'react'
import moment from 'moment'
import { NavLink } from 'react-router-dom'
import LazyLoad from 'react-lazy-load'
import ClampLines from 'react-clamp-lines'

import './index.css';

export default class CardSession extends Component {
  state = {
    sessions: null,
    semesters: null,
  }
  componentDidMount = async () => {
    const ITEM_RESPONSE = await fetch(`/api/sessions/upcoming/${this.props.content.activity._id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin'
      }
    });
    const ITEM_DATA = await ITEM_RESPONSE.json();
    if(ITEM_DATA.auth) {
      this.setState({
        seeMore: false,
        sessions: ITEM_DATA.sessions.slice(0, 3),
        semesters: ITEM_DATA.semesters
      });
    }
  }
  render() {
    return (
      <div className = 'cardSessionContainer'>
        <div className = 'cardSessionSectionPrimary'>
          <LazyLoad offsetVertical = {800}>
            <img
              className = 'cardSessionImage'
              src = {this.props.content.activity.activityImage}
              alt = 'PlayPass Partner'
            />
          </LazyLoad>
          <div className = 'cardSectionPadded'>
            <h1 className = 'cardHeader'>{this.props.content.partner.name}</h1>
            <span className = 'cardSubtitle'>{this.props.content.activity.address ? this.props.content.activity.address.neighbourhood : this.props.content.partner.address.neighbourhood}</span>
          </div>
          <div className = 'cardSectionPadded'>
            <div className = 'cardDescriptionContainer'>
              <span className = 'cardDescriptionPartner'>{this.props.content.partner.description}</span>
            </div>
          </div>
          <div className = 'cardSectionPaddedMobile'>
            <h1 className = 'cardHeader'>{this.props.content.activity.name}</h1>
            <span className = 'cardSubtitle'>{this.props.content.partner.name} · {this.props.content.activity.ageRange[0]} – {this.props.content.activity.ageRange[1] === 100 ? '12+' : this.props.content.activity.ageRange[1]} years old · {this.props.content.activity.address ? this.props.content.activity.address.neighbourhood : this.props.content.partner.address.neighbourhood}</span>
          </div>
          <div className = 'cardSectionPaddedMobile'>
            <div className = 'cardDescriptionContainer'>
              <ClampLines
                text = {this.props.content.activity.description}
                lines = {2}
                ellipsis = '...'
                buttons = {false}
                className = 'cardDescriptionPartnerMobile'
              />
            </div>
          </div>
          <div className = 'cardSectionPaddedMobile'>
            <div className = 'cardTimeRow'>
              {
                this.props.content.session ?
                (
                  <NavLink to = {`${this.props.content.partner.slug}/${this.props.content.activity.slug}/${this.props.content.session._id}`} className = 'cardButtonPrimary'>
                    <span className = 'cardButtonText'>View details and book</span>
                  </NavLink>
                ) :
                (
                  <NavLink to = {`${this.props.content.partner.slug}/${this.props.content.activity.slug}`} className = 'cardButtonPrimary'>
                    <span className = 'cardButtonText'>View details and book</span>
                  </NavLink>
                )
              }
            </div>
          </div>
          {
            this.state.sessions && this.state.sessions.length > 0 ?
            (
              <div className = 'cardSectionPaddedMobile'>
                {
                  this.props.content.session ?
                  (
                    <h1 className = 'cardSubheader'>Other upcoming times</h1>
                  ) :
                  (
                    <h1 className = 'cardSubheader'>Upcoming times</h1>
                  )
                }
                <div className = 'cardTimeRow'>
                  {
                    this.state.sessions.map(content => {
                      return (
                        <NavLink to = {`${content.partner.slug}/${content.activity.slug}/${content.session._id}`} className = 'cardButton'>
                          <span className = 'cardButtonTextSmall'>{moment.unix(content.session.startDate).format('ddd, MMM D')}</span>
                          <span className = 'cardButtonTextSmall'>{moment.unix(content.session.startDate).format('h:mm a')} - {moment.unix(content.session.endDate).format('h:mm a')}</span>
                        </NavLink>
                      )
                    })
                  }
                </div>
              </div>
            ) :
            (
              <div />
            )
          }
          {
            this.state.semesters && this.state.semesters.length > 0 ?
            (
              <div className = 'cardSectionPaddedMobile'>
                <h1 className = 'cardSubheader'>Upcoming Semesters</h1>
                <div className = 'cardTimeRow'>
                  {
                    this.state.semesters.map(content => {
                      return (
                        <NavLink to = {`${content.partner.slug}/${content.activity.slug}/${content.session._id}`} className = 'cardButton'>
                          <span className = 'cardButtonTextSmall'>{moment.unix(content.session.startDate).format('MMM D')} - {moment.unix(content.session.endDate).format('MMM D')}</span>
                          <span className = 'cardButtonTextSmall'>{content.session.frequency}</span>
                        </NavLink>
                      )
                    })
                  }
                </div>
              </div>
            ) :
            (
              <div />
            )
          }
        </div>
        <div className = 'cardSessionSectionSecondary'>
          <div className = 'cardSectionPadded'>
            <h1 className = 'cardHeader'>{this.props.content.activity.name}</h1>
            <span className = 'cardSubtitle'>{this.props.content.partner.name} · {this.props.content.activity.ageRange[0]} – {this.props.content.activity.ageRange[1] === 100 ? '12+' : this.props.content.activity.ageRange[1]} years old · {this.props.content.activity.address ? this.props.content.activity.address.neighbourhood : this.props.content.partner.address.neighbourhood}</span>
            {
              this.props.content.session ?
              (
                <p className = 'cardSubtitle'>{moment.unix(this.props.content.session.startDate).format('h:mm a')} - {moment.unix(this.props.content.session.endDate).format('h:mm a')}</p>
              ) :
              (
                <div />
              )
            }
          </div>
          <div className = 'cardSectionPadded'>
            <div className = 'cardDescriptionContainer'>
              <span className = 'cardDescriptionPartner'>{this.props.content.activity.description}</span>
            </div>
          </div>
          <div className = 'cardSectionPadded'>
            <div className = 'cardTimeRow'>
              {
                this.props.content.session ?
                (
                  <NavLink to = {`${this.props.content.partner.slug}/${this.props.content.activity.slug}/${this.props.content.session._id}`} className = 'cardButtonPrimary'>
                    <span className = 'cardButtonText'>View details and book</span>
                  </NavLink>
                ) :
                (
                  <NavLink to = {`${this.props.content.partner.slug}/${this.props.content.activity.slug}`} className = 'cardButtonPrimary'>
                    <span className = 'cardButtonText'>View details and book</span>
                  </NavLink>
                )
              }
            </div>
          </div>
          {
            this.state.sessions && this.state.sessions.length > 0 ?
            (
              <div className = 'cardSectionPadded'>
                {
                  this.props.content.session ?
                  (
                    <h1 className = 'cardSubheader'>Other upcoming times</h1>
                  ) :
                  (
                    <h1 className = 'cardSubheader'>Upcoming times</h1>
                  )
                }
                <div className = 'cardTimeRow'>
                  {
                    this.state.sessions.map(content => {
                      return (
                        <NavLink to = {`${content.partner.slug}/${content.activity.slug}/${content.session._id}`} className = 'cardButton'>
                          <span className = 'cardButtonTextSmall'>{moment.unix(content.session.startDate).format('ddd, MMM D')}</span>
                          <span className = 'cardButtonTextSmall'>{moment.unix(content.session.startDate).format('h:mm a')} - {moment.unix(content.session.endDate).format('h:mm a')}</span>
                        </NavLink>
                      )
                    })
                  }
                </div>
              </div>
            ) :
            (
              <div />
            )
          }
          {
            this.state.semesters && this.state.semesters.length > 0 ?
            (
              <div className = 'cardSectionPadded'>
                <h1 className = 'cardSubheader'>Upcoming Semesters</h1>
                <div className = 'cardTimeRow'>
                  {
                    this.state.semesters.map(content => {
                      return (
                        <NavLink to = {`${content.partner.slug}/${content.activity.slug}/${content.session._id}`} className = 'cardButton'>
                          <span className = 'cardButtonTextSmall'>{moment.unix(content.session.startDate).format('MMM D')} - {moment.unix(content.session.endDate).format('MMM D')}</span>
                          <span className = 'cardButtonTextSmall'>{content.session.frequency}</span>
                        </NavLink>
                      )
                    })
                  }
                </div>
              </div>
            ) :
            (
              <div />
            )
          }
        </div>
      </div>
    );
  }
}