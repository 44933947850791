import React, { Component } from 'react'

import NotFoundImage from '@/assets/images/not-found.png'

import './index.css'

export default class Notice extends Component {
  render() {
    return (
      <div className = 'noticeContainer'>
        <h1 className = 'notice'>Due to the current COVID-19 pandemic, we've decided not to post any activites until it's safe for you and your children.</h1>
        <h2 className = 'noticeSubtitle'>Please check back later for the best kids activities in your neighbourhoods.</h2>
        <img
          src = {NotFoundImage}
          alt = 'PlayPass not found'
          className = 'noticeImage'
        />
      </div>
    )
  }
}