import React, { Component } from 'react';
import moment from 'moment';

import AmericanExpress from '@/assets/logos/paymentMethods/americanexpress.png';
import Mastercard from '@/assets/logos/paymentMethods/mastercard.png';
import Visa from '@/assets/logos/paymentMethods/visa.png';

import './index.css';

export default class CheckoutPaymentSummary extends Component {
  render() {
    if(this.props.cancel) {
      return (
        <div className = 'checkoutSummarySection'>
          <div className = 'defaultReceiptContainer'>
            <span className = 'defaultHeaderText'>{this.props.activity.name}</span>
            <span className = 'defaultText'>{this.props.partner.name}</span>
            <span className = 'defaultSmallText'>{this.props.address}</span>
          </div>
          {
            this.props.bookings[0].type === 'term' ?
            (
              <div className = 'defaultReceiptContainer'>
                <span className = 'finalPriceLineText'>Full Term</span>
                <span className = 'priceTimingText'>{moment.unix(this.props.session.startDate).format('MMM Do YYYY')} - {moment.unix(this.props.session.endDate).format('MMM Do YYYY')}</span>
                <span className = 'priceTimingText'>{this.props.session.frequency}</span>
              </div>
            ) :
            (
              <div className = 'defaultReceiptContainer'>
                <span className = 'finalPriceLineText'>Drop-in Class</span>
                <span className = 'priceTimingText'>{moment.unix(this.props.session.startDate).format('dddd MMMM Do')}</span>
                <span className = 'priceTimingText'>{moment.unix(this.props.session.startDate).format('h:mm a')} - {moment.unix(this.props.session.endDate).format('h:mm a')}</span>
              </div>
            )
          }
          {
            this.props.refund ?
            (
              <div>
                <div className = 'receiptLineItemRow'>
                  <span className = 'priceLineText'>1 x Child</span>
                  <span className = 'discountPriceLineText'>CA${(this.props.refund).toFixed(2)}</span>
                </div>
                {
                  this.props.activity.taxable ? (
                    <div className = 'receiptLineItemRow'>
                      <span className = 'priceLineText'>HST</span>
                      <span className = 'discountPriceLineText'>${(this.props.tax).toFixed(2)}</span>
                    </div>
                  ) :
                  (
                    <div />
                  )
                }
                <div className = 'receiptLineItemRow'>
                  <span className = 'finalPriceLineText'>Total Refund</span>
                  <span className = 'finalPriceLineText'>CA${(this.props.refund + this.props.tax).toFixed(2)}</span>
                </div>
                <div className = 'receiptPaymentTypeContainer'>
                  <span className = 'priceLineText'>All refunds are credited towards your account balance to spend on future activities</span>
                </div>
              </div>
            ) :
            (
              <span className = 'finalPriceLineText'>Refund is not available due to the cancellation policy of {this.props.partner.name}.</span>
            )
          }
        </div>
      );
    }
    return (
      <div className = 'checkoutSummarySection'>
        <div className = 'defaultReceiptContainer'>
          <span className = 'defaultHeaderText'>{this.props.activity.name}</span>
          <span className = 'defaultText'>{this.props.partner.name}</span>
          <span className = 'defaultSmallText'>{this.props.address}</span>
        </div>
        {
          this.props.sessionsRemaining ?
          (
            <div className = 'defaultReceiptContainer'>
              <span className = 'finalPriceLineText'>Full Term</span>
              <span className = 'priceTimingText'>{moment.unix(this.props.session.startDate).format('MMM Do YYYY')} - {moment.unix(this.props.session.endDate).format('MMM Do YYYY')}</span>
              <span className = 'priceTimingText'>{this.props.session.frequency}</span>
              <span className = 'priceTimingText'>{this.props.sessionsRemaining.length} {this.props.sessionsRemaining === 1 ? 'session' : 'sessions'} remaining</span>
            </div>
          ) :
          (
            <div className = 'defaultReceiptContainer'>
              <span className = 'finalPriceLineText'>Drop-in Class</span>
              <span className = 'priceTimingText'>{moment.unix(this.props.session.startDate).format('dddd MMMM Do')}</span>
              <span className = 'priceTimingText'>{moment.unix(this.props.session.startDate).format('h:mm a')} - {moment.unix(this.props.session.endDate).format('h:mm a')}</span>
            </div>
          )
        }
        <div className = 'receiptLineItemRow'>
          <span className = 'priceLineText'>1 x Child</span>
          <span className = 'priceLineText'>CA${(this.props.price).toFixed(2)}</span>
        </div>
        {
          this.props.activity.taxable ? (
            <div className = 'receiptLineItemRow'>
              <span className = 'priceLineText'>HST</span>
              <span className = 'priceLineText'>${(this.props.tax).toFixed(2)}</span>
            </div>
          ) :
          (
            <div />
          )
        }
        {
          this.props.accountBalance && this.props.accountBalance > 0 && this.props.price > 0 ?
          (
            <div className = 'receiptLineItemRow'>
              <span className = 'priceLineText'>Account Balance</span>
              <span className = 'discountPriceLineText'>- CA${this.props.accountBalance >= this.props.price + this.props.tax ? (this.props.price + this.props.tax).toFixed(2) : this.props.price + this.props.tax - this.props.accountBalance <= 0.50 ? ((this.props.price + this.props.tax - 0.50)).toFixed(2) : (this.props.accountBalance).toFixed(2)}</span>
            </div>
          ) :
          (
            <div />
          )
        }
        <div className = 'receiptLineItemRow'>
          <span className = 'finalPriceLineText'>Total</span>
          <span className = 'finalPriceLineText'>CA${this.props.accountBalance >= this.props.price + this.props.tax ? (0).toFixed(2) : this.props.price + this.props.tax - this.props.accountBalance <= 0.50 ? (0.50).toFixed(2) : ((this.props.price + this.props.tax - this.props.accountBalance)).toFixed(2)}</span>
        </div>
        <div className = 'receiptPaymentTypeContainer'>
          <span className = 'defaultSmallText'>Always pay the lowest price with the PlayPass Guarantee</span>
          <span className = 'priceLineText'>All transactions are secure and fully SSL encrypted</span>
          <ol className = 'receiptPaymentLogoRow'>
            <li>
              <img
                className = 'receiptPaymentLogo'
                src = {AmericanExpress}
                alt = 'American Express PlayPass child activity booking payment option'
              />
            </li>
            <li>
              <img
                className = 'receiptPaymentLogo'
                src = {Mastercard}
                alt = 'Mastercard PlayPass child activity booking payment option'
              />
            </li>
            <li>
              <img
                className = 'receiptPaymentLogo'
                src = {Visa}
                alt = 'Visa PlayPass child activity booking payment option'
              />
            </li>
          </ol>
        </div>
      </div>
    );
  }
}