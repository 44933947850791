import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import FormAddChild from '@/components/FormAddChild';

import './index.css';

class ModalAddChild extends Component {
  render() {
    return (
      <div>
        <div className = 'overlay' />
        <div className = 'modalContainer'>
          <div className = 'modalSection'>
            <div className = 'close' onClick = {() => {this.props.handleCloseModal()}}/>
            {
              this.props.children ?
              (
                <div>
                  <div className = 'modalHeader'>
                    <h2 className = 'checkoutHeaderText'>Pick an existing child</h2>
                  </div>
                  <form className = 'formFieldContainer'>
                    <div className = 'formSection'>
                      <select
                        onChange = {(e) => this.props.selectChild(e.target.selectedOptions[0].id)}>
                        <option selected disabled defaultValue> -- select a child -- </option>
                        {
                          this.props.children.map(child => {
                            return(
                              <option id = {child._id}>{child.firstName} {child.lastName}</option>
                            );
                          })
                        }
                      </select>
                    </div>
                  </form>
                  <div className = 'modalHeader'>
                    <h2 className = 'checkoutHeaderText'>Or add a new child</h2>
                  </div>
                </div>
              ) :
              (
                <div className = 'modalHeader'>
                  <h2 className = 'checkoutHeaderText'>Add a child</h2>
                </div>
              )
            }
            <FormAddChild
              selectChild = {this.props.selectChild}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ModalAddChild);