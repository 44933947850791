import React, { Component } from 'react';

import ParentTestimonial from '@/assets/images/the-workaround.jpg';

import './index.css';

export default class Testimonial extends Component {
  render() {
    return (
      <div className = 'testimonialContainer'>
        <div className = 'testimonialSection'>
          <div className = 'testimonialPrimary'>
            <img
              className = 'testimonialImage'
              src = {ParentTestimonial}
              alt = 'PlayPass testimonial for the best children activities'
            />
          </div>
          <div className = 'testimonialSecondary'>
            <h1 className = 'testimonialHeader'>Trusted by over 1000 Toronto Parents</h1>
            <h2 className = 'testimonialSubheader'>"I never would have found gymnastics for Cara if it wasn't for PlayPass!"</h2>
            <div className = 'testimonialValuePropParent'>
              <h3 className = 'testimonialSubtitle'>– Liz, mother of Joseph (2 years) and Cara (7 years)</h3>
            </div>
          </div>
        </div>
      </div>
    );
  }
}