import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Spinner from '@/components/Spinner';

import './index.css';

class FormSignup extends Component {
  state = {
    error: null,
    signingUp: false,
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    password: null,
    confirmPassword: null
  }
  signup = async (ev) => {
    ev.preventDefault();
    this.setState({
      error: null,
      signingUp: true
    });
    if(!this.state.firstName || !this.state.lastName) {
      this.setState({
        error: 'Please enter a valid name',
        signingUp: false
      });
    } else if(!this.state.email) {
      this.setState({
        error: 'Please enter a valid email address',
        signingUp: false
      });
    } else if(!this.state.phone) {
      this.setState({
        error: 'Please enter a valid phone number',
        signingUp: false
      });
    } else if(!this.state.password || this.state.password.length < 6) {
      this.setState({
        error: 'Password must be at least 6 characters',
        signingUp: false
      });
    } else if(!this.state.confirmPassword) {
      this.setState({
        error: 'Please confirm your password',
        signingUp: false
      });
    } else if(this.state.confirmPassword !== this.state.password) {
      this.setState({
        error: 'Passwords don\'t match',
        signingUp: false
      });
    } else {
      const SIGNUP_RESPONSE = await fetch('/api/users/create/parent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': 'Origin',
        },
        body: JSON.stringify({
          user: {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            phone: this.state.phone,
            password: this.state.password
          },
          ref: null,
          source: null
        })
      });

      const SIGNUP_DATA = await SIGNUP_RESPONSE.json()

      if(SIGNUP_DATA.auth) {
        await this.props.redirectOnSignup(SIGNUP_DATA.authToken);
      } else {
        this.setState({
          signingUp: false,
          error: SIGNUP_DATA.error
        });
      }
    }
  }
  render() {
    if(this.state.signingUp) {
      return <Spinner />
    }
    return (
      <form className = 'formFieldContainer' onSubmit = {this.signup}>
        <input
          onChange = {(e) => this.setState({ firstName : e.target.value })}
          type = 'text'
          placeholder = 'First name'
          value = {this.state.firstName}
        />
        <input
          onChange = {(e) => this.setState({ lastName : e.target.value })}
          type = 'text'
          placeholder = 'Last name'
          value = {this.state.lastName}
        />
        <input
          onChange = {(e) => this.setState({ email : e.target.value })}
          type = 'email'
          placeholder = 'Email address'
          value = {this.state.email}
        />
        <input
          onChange = {(e) => this.setState({ phone : e.target.value })}
          type = 'tel'
          placeholder = 'Phone number'
          value = {this.state.phone}
        />
        <input
          onChange = {(e) => this.setState({ password : e.target.value })}
          type = 'password'
          placeholder = 'Password (at least 6 characters)'
          value = {this.state.password}
        />
        <input
          onChange = {(e) => this.setState({ confirmPassword : e.target.value })}
          type = 'password'
          placeholder = 'Confirm password (at least 6 characters)'
          value = {this.state.confirmPassword}
        />
        <div className = 'formSection'>
          <span className = 'defaultErrorText'>{this.state.error}</span>
        </div>
        <button>Sign Up</button>
      </form>
    );
  }
}

export default withRouter(FormSignup);