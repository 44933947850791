import React, { PureComponent } from 'react'
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom"
import { Helmet } from 'react-helmet'

import { STORAGE_KEY } from '@/constants'

import Header from '@/components/Header'
import Footer from '@/components/Footer'

import Splash from '@/pages/Splash'
import Blog from '@/pages/Blog'
import Post from '@/pages/Post'
import PlayPassGuarantee from '@/pages/PlayPassGuarantee'
import TermsOfService from '@/pages/TermsOfService'
import PrivacyPolicy from '@/pages/PrivacyPolicy'
import Login from '@/pages/Login'
import Signup from '@/pages/Signup'
import ResetPassword from '@/pages/ResetPassword'
import SetPassword from '@/pages/SetPassword'
import Profile from '@/pages/Profile'
import ChangePassword from '@/pages/ChangePassword'
import Logout from '@/pages/Logout'
import Providers from '@/pages/Providers'
import Provider from '@/pages/Provider'
import Semesters from '@/pages/Semesters'
import Activities from '@/pages/Activities'
import Activity from '@/pages/Activity'
import Checkout from '@/pages/Checkout'
import CancelBooking from '@/pages/CancelBooking'
import BookingCancelled from '@/pages/BookingCancelled'
import BookingConfirmation from '@/pages/BookingConfirmation'
import ReviewClass from '@/pages/ReviewClass'
import Unsubscribe from '@/pages/Unsubscribe'
import NotFound from '@/pages/NotFound'
import Notice from '@/pages/Notice'

import './App.css'

export default class App extends PureComponent {
  state = {
    user: null,
  }
  componentDidMount = async () => {
    try {
      await this.updateUser()
    } catch (error) {
      console.log('error:', error)
    }
  }
  updateUser = async () => {
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    if(DEMO_TOKEN){
      const AUTH_RESPONSE = await fetch(`/api/users/auth/parent`, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + DEMO_TOKEN,
          'x-access-token': DEMO_TOKEN
        }
      })
      const AUTH_DATA = await AUTH_RESPONSE.json()
      if(AUTH_DATA.auth) {
        this.setState({
          user: AUTH_DATA.user
        })
        return true
      }
    } else {
      this.setState({
        user: null
      })
      return false
    }
    return false
  }
  handleLogin = async (token) => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    await localStorage.setItem(STORAGE_KEY, token)
    await this.updateUser()
  }
  resetToken = async () => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    await localStorage.removeItem(STORAGE_KEY)
    await this.updateUser()
  }
  render() {
    // return (
    //   <BrowserRouter>
    //     <div className = 'appContainer'>
    //       <Helmet>
    //         <title>PlayPass</title>
    //         <meta name="description" content="PlayPass helps you find and try children's activities in Toronto. Discover new kid activities in Toronto!" />
    //       </Helmet>

    //       <Header global = {this.state} />
    //       <Switch>
    //         <PublicOnlyRoute exact path = '/' component = {Splash} />
    //         <FlexRoute exact path = '/blog' component = {Blog} />
    //         <FlexRoute exact path = '/blog/:slug' component = {Post} />
    //         <PublicOnlyRoute exact path = '/signup' component = {Signup} global = {this.state} handleLogin = {this.handleLogin} />
    //         <PublicOnlyRoute exact path = '/login' component = {Login} global = {this.state} handleLogin = {this.handleLogin} />
    //         <PublicOnlyRoute exact path = '/reset-password' component = {ResetPassword} />
    //         <PublicOnlyRoute exact path = '/set-password' component = {SetPassword} handleLogin = {this.handleLogin} />
    //         <PrivateRoute exact path = '/profile' component = {Profile} global = {this.state} resetToken = {this.resetToken} updateUser = {this.updateUser} />
    //         <PrivateRoute exact path = '/profile/change-password' component = {ChangePassword} global = {this.state} updateUser = {this.updateUser} />
    //         <PrivateRoute exact path = '/logout' component = {Logout} global = {this.state} resetToken = {this.resetToken} />
    //         <Route exact path = '/privacy-policy' component = {PrivacyPolicy} />
    //         <Route exact path = '/terms-of-service' component = {TermsOfService} />
    //         <Route exact path = '/playpass-guarantee' component = {PlayPassGuarantee} />
    //         <Route exact path = '/semesters' component = {Semesters} />
    //         <Route exact path = '/activities' component = {Activities} />
    //         <Route exact path = '/partners' component = {Providers} />
    //         <FlexRoute exact path = '/book/:sessionID' component = {Checkout} global = {this.state} updateUser = {this.updateUser} handleLogin = {this.handleLogin} />
    //         <FlexRoute exact path = '/book/:sessionID/success' component = {BookingConfirmation} global = {this.state} />
    //         <PrivateRoute exact path = '/cancel/:sessionID' component = {CancelBooking} global = {this.state} updateUser = {this.updateUser} />
    //         <PrivateRoute exact path = '/cancel/:sessionID/success' component = {BookingCancelled} global = {this.state} />
    //         <FlexRoute exact path = '/sh/:sessionID' component = {Activity} global = {this.state} updateUser = {this.updateUser} />
    //         <Route exact path = '/review' component = {ReviewClass} />
    //         <FlexRoute exact path = '/unsubscribe' component = {Unsubscribe} />
    //         <Route exact path = '/:partnerSlug' component = {Provider} />
    //         <FlexRoute exact path = '/:partnerSlug/:activitySlug' component = {Activity} global = {this.state} updateUser = {this.updateUser} />
    //         <FlexRoute exact path = '/:partnerSlug/:activitySlug/:sessionID' component = {Activity} global = {this.state} updateUser = {this.updateUser} />
    //         <Route component = {NotFound} />
    //       </Switch>
    //       <Footer />

    //     </div>
    //   </BrowserRouter>
    // )
    return (
      <BrowserRouter>
        <div className = 'appContainer'>
          <Helmet>
            <title>PlayPass</title>
            <meta name="description" content="PlayPass helps you find and try children's activities in Toronto. Discover new kid activities in Toronto!" />
          </Helmet>
          <Switch>
            <PublicOnlyRoute exact path = '/' component = {Splash} />
            <FlexRoute exact path = '/blog' component = {Blog} />
            <FlexRoute exact path = '/blog/:slug' component = {Post} />
            <PrivateRoute exact path = '/logout' component = {Logout} global = {this.state} resetToken = {this.resetToken} />
            <Route exact path = '/privacy-policy' component = {PrivacyPolicy} />
            <Route exact path = '/terms-of-service' component = {TermsOfService} />
            <FlexRoute exact path = '/unsubscribe' component = {Unsubscribe} />
            <Route component = {Notice} />
          </Switch>
          <Footer />

        </div>
      </BrowserRouter>
    )
  }
}

function PrivateRoute({ component: Component, ...rest }) {
  const DEMO_TOKEN = localStorage.getItem(STORAGE_KEY)
  return (
    <Route
      {...rest}
      render = {props =>
        DEMO_TOKEN ? (
          <Component {...props} {...rest}/>
        ) : (
          <Redirect
            to = {{
              pathname: "/signup",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  )
}

function PublicOnlyRoute({ component: Component, ...rest }) {
  const DEMO_TOKEN = localStorage.getItem(STORAGE_KEY)
  return (
    <Route
      {...rest}
      render = {props =>
        DEMO_TOKEN ? (
          <Redirect
            to = {{
              pathname: "/activities",
              state: { from: props.location }
            }}
          />
        ) : (
          <Component {...props} {...rest}/>
        )
      }
    />
  )
}

function FlexRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render = {props =>
        (
          <Component {...props} {...rest}/>
        )
      }
    />
  )
}