import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';

import { STORAGE_KEY } from '@/constants';

import './index.css';

export default class LineSemester extends Component {
  state = {
    bookings: [],
  }
  componentDidMount = async () => {
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY);
    if(this.props.content.session.prorate && this.props.content.session.startDate < moment().unix()) {
      const SEMESTER_RESPONSE = await fetch(`/api/sessions/semester/${this.props.content.session._id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': 'Origin'
        }
      });
      const SEMESTER_DATA = await SEMESTER_RESPONSE.json();
      if(SEMESTER_DATA.auth) {
        let sessions = SEMESTER_DATA.sessions
        const sessionsRemainingInTerm = await sessions.filter(session => session.session.startDate > moment().unix());
        this.setState({
          price: isNaN((this.props.content.session.costInCents * (sessionsRemainingInTerm.length/sessions.length)/100).toFixed(2)) ? 0 : (this.props.content.session.costInCents * (sessionsRemainingInTerm.length/sessions.length)/100).toFixed(2)
        });
      }
    } else {
      this.setState({
        price: (this.props.content.session.costInCents/100).toFixed(2)
      });
    }
    if(DEMO_TOKEN) {
      const BOOKING_RESPONSE = await fetch(`/api/bookings/${this.props.content.session._id}`, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + DEMO_TOKEN,
          'x-access-token': DEMO_TOKEN
        }
      });
      const BOOKING_DATA = await BOOKING_RESPONSE.json();
      if(BOOKING_DATA.auth) {
        let bookings = BOOKING_DATA.bookings
        this.setState({
          bookings: bookings.filter(booking => booking.status === 'ATTEND' && booking.type !== 'locked')
        });
      }
    }
  }
  render() {
    return (
      <div className = 'lineSemesterContainer'>
        <div className = 'lineSemesterHeader'>
          <span className = 'lineSemesterBoldText'>{this.props.content.session.frequency} from ${this.state.price}</span>
        </div>
        <div className = 'lineSemesterCardContainer'>
          <span className = 'lineSemesterTimeText'>{moment.unix(this.props.content.session.startDate).format('MMM Do YYYY')} – {moment.unix(this.props.content.session.endDate).format('MMM Do YYYY')}</span>
          <div>
            {
              this.state.bookings.length > 0 &&
              <NavLink className = 'lineCancellationText' to = {`/cancel/${this.props.content.session._id}`}>
                Cancel
              </NavLink>
            }
            {
              this.props.content.session.classFull ?
              (
                <span className = 'lineSemesterErrorText'>Class full</span>
              ) :
              (
                <NavLink className = 'lineSemesterNavLink' to = {`/book/${this.props.content.session._id}`}>
                  <button>
                    Register
                  </button>
                </NavLink>
              )
            }
          </div>
        </div>
      </div>
    );
  }
}