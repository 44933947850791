import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { StripeProvider } from 'react-stripe-elements';
import moment from 'moment';

import { STORAGE_KEY } from '@/constants';
import { initializeAdTracking } from '@/analytics';

import Spinner from '@/components/Spinner'
import CheckoutChildSection from '@/components/CheckoutChildSection'
import CheckoutAddDetailsSection from '@/components/CheckoutAddDetailsSection'
import CheckoutElements from '@/components/CheckoutElements'
import CheckoutPaymentSummary from '@/components/CheckoutPaymentSummary'

import NotFound from '@/pages/NotFound';

import './index.css';

class Checkout extends Component {
  state = {
    loading: true,
    error: null,
    child: null,
  }
  componentDidMount = async () => {
    initializeAdTracking(this.props.location.pathname);
    const SESSION_RESPONSE = await fetch(`/api/sessions/${this.props.match.params.sessionID}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin'
      }
    });
    const SESSION_DATA = await SESSION_RESPONSE.json();
    let price, tax = 0, session, activity, partner, address, sessionsRemaining, showCreditCardFields
    if(SESSION_DATA.auth){
      session = SESSION_DATA.session
      activity = SESSION_DATA.activity
      partner = SESSION_DATA.partner
      if(activity.address) {
        address = (activity.address.addressLine2 ? activity.address.addressLine2 + ' - ' : '') + activity.address.addressLine1 + ', ' + activity.address.city + ', ' + activity.address.postalCode
      } else {
        address = (partner.address.addressLine2 ? partner.address.addressLine2 + ' - ' : '') + partner.address.addressLine1 + ', ' + partner.address.city + ', ' + partner.address.postalCode
      }
      if(!session.costInCents && session.costInCents !== 0) {
        price = activity.costInCents / 100
      } else {
        const SEMESTER_RESPONSE = await fetch(`/api/sessions/semester/${session._id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Headers': 'Origin'
          }
        });
        const SEMESTER_DATA = await SEMESTER_RESPONSE.json();
        if(SEMESTER_DATA.auth) {
          let sessions = SEMESTER_DATA.sessions
          sessionsRemaining = await sessions.filter(session => session.session.startDate > moment().unix())
          if(session.prorate) {
            price = session.costInCents * sessionsRemaining.length / sessions.length /100
          } else {
            price = session.costInCents / 100
          }
        } else {
          price = session.costInCents / 100
        }
      }
      if(activity.taxable) {
        tax = price * activity.taxRate
      }
    }
    if(price <= 0 || (this.props.global.user && (this.props.global.user.accountBalanceInCents / 100) >= (price + tax))) {
      showCreditCardFields = false
    } else {
      showCreditCardFields = true
    }

    await this.setState({
      name: this.props.global.user ? `${this.props.global.user.firstName} ${this.props.global.user.lastName}` : null,
      showCreditCardFields,
      address,
      activity,
      partner,
      session,
      tax,
      price,
      sessionsRemaining,
      loading: false
    });
  }
  selectChild = (child) => {
    this.setState({
      child
    })
  }
  updateName = (name) => {
    this.setState({
      name
    })
  }
  handleAttemptPayment = () => {
    this.setState({
      attemptPayment: true
    });
  }
  disablePaymentButton = () => {
    this.setState({
      disabledButton: true
    });
  }
  bookClass = async (token) => {
    if (this.state.showCreditCardFields && token && token.error){
      this.setState({
        error: token.error ? token.error.message : 'Something went wrong, please try again!',
        attemptPayment: false,
        disabledButton: false,
      });
    } else if(!this.state.child || !this.props.global.user) {
      this.setState({
        error: 'Please enter all required information.',
        attemptPayment: false,
        disabledButton: false,
      });
    } else if (this.state.showCreditCardFields && !token) {
      this.setState({
        error: 'Please enter your payment details.',
        attemptPayment: false,
        disabledButton: false,
      });
    } else {
      const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY);
      const BOOKING_RESPONSE = await fetch('/api/bookings/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': 'Origin',
          'Authorization': 'Bearer ' + DEMO_TOKEN,
          'x-access-token': DEMO_TOKEN
        },
        body: JSON.stringify({
          itemID: this.state.session._id,
          child: this.state.child,
          token: token ? token.token.id : null,
        })
      });

      const BOOKING_DATA = await BOOKING_RESPONSE.json();

      if(BOOKING_DATA.auth){
        this.props.history.push(`/book/${this.props.match.params.sessionID}/success`);
      } else {
        this.setState({
          error: BOOKING_DATA.error,
          attemptPayment: false,
          disabledButton: false,
        });
      }
    }
  }
  render() {
    const stripeKey = process.env.NODE_ENV === 'production' ? 'pk_live_YtF1IvNXe7dxudo3mj5GanTr' : 'pk_test_ePqMAfXQ8yQuK7mYQQgaZavV'
    if(this.state.attemptPayment || this.state.loading) {
      return <Spinner />
    }
    if(!this.state.session){
      return <NotFound />
    }
    return (
      <div className = 'checkoutContainer'>
        <div className = 'checkoutFocusSection'>
          <h1 className = 'defaultHeaderText'>Finish booking your class!</h1>
          <CheckoutChildSection
            child = {this.state.child}
            selectChild = {this.selectChild}
            user = {this.props.global.user}
          />
          <CheckoutAddDetailsSection
            updateUser = {this.props.updateUser}
            handleLogin = {this.props.handleLogin}
            user = {this.props.global.user}
          />
          <StripeProvider apiKey = {stripeKey}>
            <CheckoutElements
              updateName = {this.updateName}
              bookClass = {this.bookClass}
              handleAttemptPayment = {this.handleAttemptPayment}
              disablePaymentButton = {this.disablePaymentButton}
              name = {this.state.name}
              disableButton = {this.state.disableButton}
              showCreditCardFields = {this.state.showCreditCardFields}
              error = {this.state.error}
            />
          </StripeProvider>
        </div>

        <CheckoutPaymentSummary
          accountBalance = {this.props.global.user ? this.props.global.user.accountBalanceInCents / 100 : 0 }
          session = {this.state.session}
          activity = {this.state.activity}
          partner = {this.state.partner}
          sessionsRemaining = {this.state.sessionsRemaining}
          address = {this.state.address}
          price = {this.state.price}
          tax = {this.state.tax}
        />
      </div>
    );
  }
}

export default withRouter(Checkout);