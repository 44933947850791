import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import { initializeAdTracking } from '@/analytics';

import Checkmark from '@/assets/iconography/checkmark.svg';

import Spinner from '@/components/Spinner';
import NotFound from '@/pages/NotFound';

import './index.css';

export default class BookingCancelled extends Component {
  state = {
    loading: true
  }
  componentDidMount = async () => {
    initializeAdTracking(this.props.location.pathname);
    const SESSION_RESPONSE = await fetch(`/api/sessions/${this.props.match.params.sessionID}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin'
      }
    });
    const SESSION_DATA = await SESSION_RESPONSE.json();
    if(SESSION_DATA.auth) {
      this.setState({
        session: SESSION_DATA.session,
        partner: SESSION_DATA.partner,
        activity: SESSION_DATA.activity,
        loading: false
      });
    } else {
      this.setState({
        loading: false
      });
    }
  }
  render() {
    if(this.state.loading) {
      return <Spinner />
    }
    if(!this.state.session) {
      return <NotFound />
    }
    return (
      <div className = 'bookingConfirmationContainer'>
        <div className = 'paymentConfirmationCheckmarkContainer'>
          <img
            className = 'paymentConfirmationCheckmark'
            src = {Checkmark}
            alt = 'Find new children activities'
          />
        </div>
        <div className = 'bookingConfirmationHeaderContainer'>
          <h1>Cancellation successful!</h1>
          <span className = 'defaultText'>Your booking for <strong>{this.state.activity.name}</strong> was successfully cancelled!</span>
        </div>
        <div className = 'bookingConfirmationShareContainer'>
          <span className = 'defaultSubheaderText'>Find another activity!</span>
          <span className = 'defaultText'>Did you know the average parent takes their children to 9 activities per month?</span>
          <NavLink className = 'bookingReengageNav' to = '/activities'>
            <button>
              Book another activity!
            </button>
          </NavLink>
        </div>
      </div>
    );
  }
}