import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import StarOutline from '@/assets/iconography/starOutline.svg'
import StarFilled from '@/assets/iconography/starFilled.svg'

import './index.css';

class FormReview extends Component {
  send = async (ev) => {
    ev.preventDefault()
    await this.props.sendReview()
  }
  render() {
    return (
      <form className = 'formFieldContainer' onSubmit = {this.send}>
        <div className = 'formSection'>
          <div>
            <img
              className = 'formReviewStar'
              onClick = {() => this.props.setRating(1)}
              src = {this.props.rating >= 1 ? StarFilled : StarOutline}
              alt = 'PlayPass review for kids activities'
            />
            <img
              className = 'formReviewStar'
              onClick = {() => this.props.setRating(2)}
              src = {this.props.rating >= 2 ? StarFilled : StarOutline}
              alt = 'PlayPass review for kids activities'
            />
            <img
              className = 'formReviewStar'
              onClick = {() => this.props.setRating(3)}
              src = {this.props.rating >= 3 ? StarFilled : StarOutline}
              alt = 'PlayPass review for kids activities'
            />
            <img
              className = 'formReviewStar'
              onClick = {() => this.props.setRating(4)}
              src = {this.props.rating >= 4 ? StarFilled : StarOutline}
              alt = 'PlayPass review for kids activities'
            />
            <img
              className = 'formReviewStar'
              onClick = {() => this.props.setRating(5)}
              src = {this.props.rating >= 5 ? StarFilled : StarOutline}
              alt = 'PlayPass review for kids activities'
            />
          </div>
          <textarea
            onChange = {(e) => this.props.setReview(e.target.value)}
            type = "text"
            placeholder = 'Your review helps other parents pick the best activities!'
            className = {this.props.rating && this.props.rating < 4 && this.props.review.length <= 0 ? 'inputError' : null}
          />
        </div>

        <div className = 'formSection'>
          <span className = 'defaultErrorText'>{this.props.error}</span>
        </div>

        <button>
          <span>Submit review</span>
        </button>
      </form>
    );
  }
}

export default withRouter(FormReview);