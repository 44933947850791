import React, { Component } from 'react';

import './index.css';

export default class HowItWorks extends Component {
  render() {
    return (
      <div className = 'howToContainer'>
        <div className = 'howToSection'>
          <h1 className = 'howToHeader'>How it works</h1>
          <div className = 'howToStepsContainer'>
            <div className = 'howToStep'>
              <h1 className = 'howToStepHeader'><span className = 'howToStepNumber'>1</span>We List Activities</h1>
              <p className = 'howToStepText'>Get all your neighbourhoods kids activities listed in one place.</p>
            </div>
            <div className = 'howToStep'>
              <h1 className = 'howToStepHeader'><span className = 'howToStepNumber'>2</span>Book Through PlayPass</h1>
              <p className = 'howToStepText'>Learn about activities to find and book the perfect one.</p>
            </div>
            <div className = 'howToStep'>
              <h1 className = 'howToStepHeader'><span className = 'howToStepNumber'>3</span>Just Show Up!</h1>
              <p className = 'howToStepText'>Don't fuss with reservations, logistics, or anything else - your spot is reserved so just show up!</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}