import React, { Component } from 'react';

import ModalAddDetails from '@/components/ModalAddDetails';
import ModalAuth from '@/components/ModalAuth';

// import './index.css';

export default class CheckoutAddDetailsSection extends Component {
  state = {
    authModal: false,
    addDetailsModal: false,
    error: null,
  }
  handleCloseModal = () => {
    this.setState({
      authModal: false,
      addDetailsModal: false,
      error: null
    })
  }
  toggleDetailsModal = () => {
    this.setState({
      addDetailsModal: true,
      error: null
    })
  }
  toggleLoginModal = () => {
    this.setState({
      authModal: true,
      error: null
    })
  }  
  updateName = (e) => {
    this.setState({
      name: e.target.value,
      error: null
    })
  }
  handleLogin = async (token) => {
    await this.props.handleLogin(token)
    await this.handleCloseModal()
  }
  updateUser = async () =>{
    const auth = await this.props.updateUser()
    if(auth){
      setTimeout(() => {
        this.handleCloseModal()
      }, 1000);
    }
  }
  render() {
    return (
      <div className = 'checkoutFormSection'>
        {
          this.state.addDetailsModal ? (
            <ModalAddDetails
              handleCloseModal = {this.handleCloseModal}
              user = {this.props.user}
              redirectOnSignup = {this.handleLogin}
              updateUser = {this.updateUser}
            />
          ) : this.state.authModal ? (
            <ModalAuth
              handleCloseModal = {this.handleCloseModal}
              handleLogin = {this.handleLogin}
            />
          ) : (
            <div />
          )
        }
        <div className = 'checkoutHeaderSection'>
          <h2 className = 'checkoutHeaderText'>Your details</h2>
        </div>
        <div className = 'checkoutDetailSection'>
          {
            this.props.user ?
            (
              <div className = 'checkoutDetailFilled'>
                <span>{this.props.user.firstName} {this.props.user.lastName}</span>
                <span>{this.props.user.email}</span>
                <span>{this.props.user.phone}</span>
                <span className = 'checkoutAddDetailsText' onClick = {this.toggleDetailsModal}>Edit</span>
              </div>
            ) :
            (
              <div className = 'checkoutDetailFilled'>
                <span className = 'checkoutAddDetailsText' onClick = {this.toggleDetailsModal}>Add your details</span>
                <span className = 'defaultSmallText'>Have an account already? <span className = 'checkoutAddDetailsText defaultSmallText' onClick = {this.toggleLoginModal}>Login</span></span>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}