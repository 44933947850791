import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import { initializeAdTracking } from '@/analytics';

import FormChangePassword from '@/components/FormChangePassword';

import './index.css';

class ChangePassword extends Component {
  componentDidMount = () => {
    initializeAdTracking(this.props.location.pathname);
  }
  render() {
    return (
      <div className = 'changePasswordContainer'>
        <h1 className = 'changePasswordHeaderText'>Change password</h1>
        <span className = 'changePasswordSubtitleText'>Update your password. Passwords must be at least 6 characters long</span>
        <FormChangePassword
          user = {this.props.global.user}
          updateUser = {this.props.updateUser}
        />
        <NavLink to = '/profile' className = 'changePasswordNavSection'>
          <button className = 'changePasswordbuttonOffWhite'>Go back</button>
        </NavLink>
      </div>
    );
  }
}

export default withRouter(ChangePassword);