import React, { Component } from 'react'
import LazyLoad from 'react-lazy-load'
import { NavLink } from 'react-router-dom'

import './index.css'

export default class CardProvider extends Component {
  render() {
    return (
      <div className = 'defaultCardContainer'>
        <div className = 'partnerImageContainer'>
          <LazyLoad offsetVertical = {600}>
            <img
              className = 'partnerCardImage'
              src = {this.props.partner.logo}
              alt = 'PlayPass Partner'
            />
          </LazyLoad>
        </div>
        <div className = 'defaultCardInfoBox'>
          <NavLink className = 'defaultNavLink' to = {`/${this.props.partner.slug}`}>
            <span className = 'defaultHeaderTextColor'>{this.props.partner.name}</span>
          </NavLink>
          <span className = 'defaultSmallText'>{this.props.partner.address ? (this.props.partner.address.addressLine2 ? this.props.partner.address.addressLine2 + ' - ' : '') + this.props.partner.address.addressLine1  + ', ' + this.props.partner.address.city + ', ' + this.props.partner.address.postalCode : ''}</span>
          <span className = 'defaultSmallText'>{this.props.partner.address ? this.props.partner.address.neighbourhood : ''}</span>
          <br />
          <span className = 'defaultSmallText'>{this.props.partner.description}</span>
          <NavLink className = 'defaultNavLink' to = {`/${this.props.partner.slug}`}>
            <button>
              View Partner
            </button>
          </NavLink>
        </div>
      </div>
    );
  }
}