import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import { initializeAdTracking } from '@/analytics';

import FormSignup from '@/components/FormSignup';

class Signup extends Component {
  componentDidMount = () => {
    initializeAdTracking(this.props.location.pathname);
  }
  render() {
    return (
      <div className = 'formContainer'>
        <h1 className = 'defaultHeaderText'>Explore and discover new kid activities easily</h1>
        <span className = 'defaultSubtitleText'>Save time finding the best kid activities in Toronto with PlayPass</span>
        <FormSignup
          redirectOnSignup = {this.props.handleLogin}
        />
        <NavLink to = '/login' className = 'formSection'>
          <span className = 'defaultPromptText'>Already have an account? Login!</span>
        </NavLink>
      </div>
    );
  }
}

export default withRouter(Signup);